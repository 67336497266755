.mainlogin {
  width: 100%;
}

.registrationform{
  width: 70%;
  margin-inline: auto;
  margin-top: 50px;
  height: max-content;
  margin-bottom: 100px;
}
.registrationform > form{
  width: 500px;
  margin: auto;
  height: 280px;
  background: linear-gradient(180deg, #078BE6 0%, #19469D 100%);
  border-radius: 20px;
  box-shadow: rgba(0, 58, 17, 0.25) 0px 54px 55px, rgba(144, 142, 142, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.form-group{
  padding: 6px 26px;
  

}
.form-group > input{
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: none;
  text-align: center;
  /* font-weight: 500; */
}
.buttonRegister {
  border: none;
  border-radius: 10px;
  height: 50px;
  width: 90%;
  margin: 25px;
}
.form-group > input::placeholder {
 text-align: center;
font-weight: 500;
letter-spacing: 2px;
}
.loginheader{
  background: linear-gradient(89.98deg, rgba(25, 70, 157, 0.5) 23.67%, rgba(0, 163, 255, 0.5) 97.94%);
background-blend-mode: luminosity;
height: 100px;
width: 90%;
margin: auto;
}

.WelcomeAdmin{
  text-align: center;
  padding-top: 20px;
color: white;
}
.loginheader > H2{
  text-align: center;
  padding-top: 22px;
  color: #fff;
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
}
button.buttonRegister {
  margin-top: 40px;
 
border-radius: 20px;
color: black;
font-weight: 700;
}
.showIcon {
 
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    opacity: 0.7;
    float: right;
    /* margin-right: 10rem; */
    margin-top: -39px;
}

.showIcon  svg{

text-align: center;
color: linear-gradient(89.98deg, rgb(25 70 157) 23.67%, rgb(0 163 255) 97.94%);
opacity: 1;
font-size: 13px;
margin-top: -4px;
}
 
.ViewCalulatedPrize{
  margin-left: 30PX;
}
.ViewCalulatedPrize > table{
  width: 600px;
  margin-top: 10px;
}
.ViewCalulatedPrize1{
  cursor: pointer;
  width: 100px;
  background: linear-gradient(89.98deg, rgb(25 70 157) 23.67%, rgb(0 163 255) 97.94%);
}


.ViewCalulatedPrize1 {
  cursor: pointer;
  width: auto;
  background: linear-gradient(89.98deg, rgb(25 70 157) 23.67%, rgb(0 163 255) 97.94%);
  height: auto;
  padding: 6px;
  border-radius: 18px;
  display: inline-block;
  color: #fff;
  padding: 10px 25px;
  text-align: center;
  margin: 10px 0;
}