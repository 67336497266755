.AccordionBody > div{
    padding: 10px;
 
}

.AccordionBody > div > .mylink {
  
    padding-left: 24px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 15.5949px;
    line-height: 19px;
    color: #FFFFFF;
}

.AccordionBody > div > .mylink:focus {
    font-weight: 700;
   
  
}

 .accordion-button:not(.collapsed) {
    background: linear-gradient(89.98deg, #19469D 23.67%, #00A3FF 97.94%);
    border-radius: 0 20px 20px 0;
} 
.accordion-button:focus {
    border-color: none;
    box-shadow: none;
}
/* .collapsing {
    -webkit-transition: none;
    transition: none;
    display: none;
} */
.accordion {
    --bs-accordion-bg: #E8E8E8;
    --bs-accordion-border-color:none;
}
.accordion-body {
    background: #8D8D8D 97.94%;
    border-radius: 0px 0px 50px 0px;
    margin-top: -14px;
    padding: 20px 2px 10PX 1PX;
    height: fit-content !important;
}
.SettingSec {
    font-style: normal;
    font-family: 'Inter';
    font-weight: 300;
    font-size: 15.5949px;
    /* margin-bottom: 15px; */
    /* margin-top: 10px; */
    /* top: 0; */
    bottom: 0;
    position: sticky;
    background: #E8E8E8;
    height: 60px;
    padding: 20px 0 0px 0px;
    border-top: 1.5px solid rgb(103, 102, 102);
}
/* .accordion-button::after {
    display: none;
} */

.accordion-button {
    padding-left: 37px !important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 15.5949px;
    line-height: 19px;
}
.auth1{

    background: linear-gradient(89.98deg, #19469D 23.67%, #00A3FF 97.94%);
    border-radius: 40px;
   width: 186px;
    height: 51px;
    color: white;   
    border: none;
    padding: 8px;
    font-size: 1.3rem;
font-weight: 400;
}
.auth1 > a{
text-decoration: none;
color: white;
cursor: pointer;

}
.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: var(--bs-accordion-inner-border-radius);
    border-top-right-radius: var(--bs-accordion-inner-border-radius);
    border-radius: 0px 20px 20px 0px;
}

.adminsidebar{
    /* width: 15% !important;
    background-color: #00A3FF; */
}

.adminsidebar {
    height: 87vh;
    overflow-y: scroll;
    overflow-x: hidden !important;
    margin-top: 20px;
    border-radius: 1px 39px 50px 0px;
}
 ::-webkit-scrollbar {
    width: 3px !important;
    position: relative;
    top: 15px;
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: #888 !important;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555 !important;
  }
.deleted a{

font-weight: 300;
margin-top: 100px;

}
.deleted{
margin-top: 9px;
margin-left: -3px;
}
.SettingSec1{
    padding: 10px 0 10px 0px;
}