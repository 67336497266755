.trtabletd>td {
   margin-top: 10px;
}

input[type="time"] {
   width: 150px;
}

.Remarksverdict {

   width: 140px !important;

}

.myselecthorseverdict {
   margin-top: 10px;
   height: 58px;
   background: linear-gradient(289deg, #19469D 23.67%, #00A3FF 97.94%);
   border-radius: 20px;
   width: 97%;
   margin-left: 18px;
}

.inputAddhorse3 {
   max-width: 95px;

}

.form-check-input {
   border: 1px solid rgba(0, 0, 0, .25) !important;
}

.inputAddhorse4 {
   max-width: 156px;
   min-width: 147px;
}

.InputAddhorse5 {
   min-width: 250px !important;
}

.myselectiondataaddHorse1 {
   height: 420px !important;
   width: 110% !important;
}

.myselectiondataaddHorse2 {

   width: 110% !important;

}


span.ant-upload-list-item-name {
   color: #000 !important;
 }

.horizontal-scroll-wrapper.squares {
   overflow-x: auto !important;
}

.myselecthorse {

   width: 110% !important;

}

.myselecthorsedata>span:nth-child(4) {
   position: relative;
   left: 125px;
   margin-left: -42px;
}






.myselecthorsedata>span:nth-child(3) {
   /* width: 21%; */
   margin-left: 121px;
}

.myselecthorsedata>span:nth-child(5) {
   margin-left: 114px !important;
   position: relative;

   left: 176px;
}

.myselecthorsedata>span:nth-child(6) {
   margin-left: 278px;
   margin-right: -24px;
}

.myselecthorsedata>span:nth-child(8) {
   margin-left: -28px;
}

.ownerinput {
   width: 200px !important;
}

.myselecthorsedata>span:last-child {

   margin-right: 15px;
}

input.timeracedata {
   height: 40px;
   border-radius: 8px;
   border: 1px solid #737373;
   padding: 10px;
   margin-left: 15px;
}