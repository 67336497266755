@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');


* {
  margin: 0;
  padding: 0;
}

body::-webkit-scrollbar {
  width: 0.1em;
}

.page::-webkit-scrollbar {
  width: 0.1em;
  background: linear-gradient(45deg, rgb(48, 8, 139), darksalmon);

}

.adminsidebar::-webkit-scrollbar {
  width: 0.1em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, rgb(48, 8, 139), darksalmon);
  width: 0.1em;
}


.rightsidedata::-webkit-scrollbar {
  width: 0.1em;
}

.rightsidedata::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.rightsidedata::-webkit-scrollbar-thumb {
  background: linear-gradient(89.98deg, #19469D 23.67%, #00A3FF 97.94%);
  width: 0.1em;
}

.horizontal-scroll-wrapper::-webkit-scrollbar {
  width: 0;
}

.horizontal-scroll-wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  display: none;
}

.horizontal-scroll-wrapper::-webkit-scrollbar-thumb {
  background: transparent;
  width: 0;
}

.horizontal-scroll-wrapper {
  cursor: grab;
}


input:-webkit-autofill {
  -webkit-box-shadow: none;
  -webkit-text-fill-background: #fff !important;
  -webkit-background-clip: #fff;
  -webkit-backdrop-filter: none;

}


button:focus,
input:focus,
a:focus {
  text-decoration: none !important;
  outline: none !important;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paginationItem {
  background: #fff;
  border: 2px solid #666;
  padding: 10px 15px;
  border-radius: 50%;
  height: 45px;
  width: 45px;
  position: relative;
  margin: 0 5px;
  cursor: pointer;
}

.paginationItem span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.prev,
.next {
  background: #fff;
  border: none;
  padding: 10px;
  color: blue;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  margin: 0 10px;
  cursor: pointer;
}

.paginationItem.active {
  border: 1px solid #888;
  color: #888;
  pointer-events: none;

}

.prev.disabled,
.next.disabled {
  pointer-events: none;
  box-shadow: none;
  color: #999;
}

.page-link {
  cursor: pointer;
}

.searchLottie {
  height: 100px !important;
}

.homeLottie {

  height: 100px !important;

}

.TableLottie {

  position: relative;
  left: 270%;
}

.TableLottie1a {
  width: 300px;
  height: 200px;
  position: relative;
  left: 100%;
}

.notfound {
  position: absolute;
  left: 40%;
  top: 40%;
  margin-top: 90px;
}

.notfound>img {
  width: 600px;
  z-index: -100;
  position: relative;
}

.popupLoader {

  height: 100px !important;
  width: 100px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userFilter {
  background: white;
}

.resultLottie {

  width: 400px;
  height: 400px;
  position: relative;
  left: 140%;
}

.resultLottie1 {

  width: 400px;
  height: 400px;
  position: relative;
  left: 40%;

}

.form{
  display: block;
}

.crossIcon2 {
  position: relative;
  /* left: -76%; */
  top: -95px;
  right: 0 !important;
  left: 10rem;
}