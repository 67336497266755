.Approvedbtn {
  border: none;
  font-size: 14px;
  color: #fff;
  height: 30px;
  border-radius: 20px;
  width: 110px;
  margin-left: -10px;
}

a.page-item {
  text-decoration: none;
  list-style: none;
  border: 1px solid rgb(156, 152, 152);
  cursor: pointer;
  padding: 8px;
  color: rgb(69, 67, 67);
}

a.page-item1 {
  text-decoration: none;
  list-style: none;
  border: 1px solid rgb(156, 152, 152);
  cursor: pointer;
  padding: 8px;
  background-color: #19469d;
  color: #fff;
}

.forflex {
  display: flex;
}

.addsectionimage {
  padding: 2rem 0;
}




.AddImages1 {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dotted black;
  border-radius: 20px;
  width: 10rem;
  height: 10rem;
  cursor: pointer;
  font-size: large;
}

.AddImages1 span {
  font-weight: lighter;
  font-size: small;
  padding-top: 0.5rem;
}

.AddImages1 input {
  display: none;
}

img {
  padding: 0;
  margin: 0;
}

.images111 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.image111 {
  margin: 1rem 0.5rem;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.image111 button {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  border: none;
  color: white;
  background-color: lightcoral;
}

.image111 button:hover {
  background-color: red;
}

.image111 p {
  padding: 0 0.5rem;
  margin: 0;
}

.upload-btn111 {
  cursor: pointer;
  display: block;
  margin: 0 auto;
  border: none;
  border-radius: 20px;
  width: 12rem;
  height: 3rem;
  color: white;
  font-size: 15px;
  background: linear-gradient(89.98deg, #19469D 23.67%, #00A3FF 97.94%);
}

/* label:hover,
.upload-btn:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
} */

.error11 {
  text-align: center;
}

.error span {
  color: red;
}

.skipbutton {
  float: right;
  width: 186px;
  height: 51px;
  background: linear-gradient(89.98deg, #19469D 23.67%, #00A3FF 97.94%);
  border-radius: 20px;
  color: white;
  border: none;
  font-size: 16px;
  margin: 20px;
}

.themesetting {
  width: 100%;
  /* margin: auto; */
  height: 300px;
  margin: 30px;
}

.themesetting>p {
  text-align: center;
  font-size: 14px;
  padding-top: 10px;
  font-family: 'inter';
}

.ForPrimaryColor {
  display: flex;
  justify-content: space-evenly;
}

.OnePrimaryColor {
  background-color: #00A3FF;
  width: 100px;
  height: 100px;
}

.TwoPrimaryColor {
  background-color: #be4030;
  width: 100px;
  height: 100px;
}

.ThreePrimaryColor {
  background-color: #aa75d6;
  width: 100px;
  height: 100px;
}

.myselectiondata>div:first-child {
  padding-top: 5ypx;
  /* flex: 1 1; */
  text-align: center;
  min-width: 116px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  /* color: #FFFFFF; */
}

.myselectiondata>div {
  flex: 2 1;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  margin-top: 18px;
  margin-right: 18px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  height: 55px;
  width: 116px;
}

.Prizeclass {
  width: 100% !important;
}

.react-date-picker--disabled {
  background-color: transparent;
}

p.SubmitHorseKindbtn {
  border: 1px solid #969191;
  width: 98px;
  text-align: center;
  border-radius: 20px;
  /* height: 30px; */
  margin-top: 10px;
  align-items: center;
  cursor: pointer;
}

:where(.css-dev-only-do-not-override-1jr9qlj).ant-upload-wrapper .ant-upload-list .ant-upload-list-item-error .ant-upload-list-item-name, :where(.css-dev-only-do-not-override-1jr9qlj).ant-upload-wrapper .ant-upload-list .ant-upload-list-item-error .ant-upload-icon .anticon {
  color: #000 !important;
}


.truncate-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
