@media (max-width: 1900px) and (min-width: 1600px) {
  .newmyselecthorsedata {
    padding-left: 145px;
  }

  .searchInputs>input select {
    margin: 24px;
    width: 222px;
    height: 40px;
    font-size: 12px;


  }

  .searchInputs>.searchDropdown {
    margin: 24px !important;
    width: 300px !important;
    height: 40px I !important;
    font-size: 12px;

  }

  .searchDropdown1 {
    width: 217px;
  }

  .newmyselecthorsedata>span:nth-child(1) {
    padding-left: 14px;
  }

  button.removebtnhorse {
    min-width: 95px;
  }

  button.savebtnhorse {
    min-width: 95px;
  }

  .accordion-button:not(.collapsed) {
    font-size: 14px;
  }

  .inputAddhorse1 {
    min-width: 116px;
  }

  .myselecthorsedata>span {
    font-size: 14px !important;
    padding: 18px !important;
  }

  .AccordionBody>div>.mylink {
    font-size: 13px;
  }

  .filtertextform>input {
    height: 42px;
    margin: 24px;
    width: 275px;
  }

  .accordion-button {
    font-size: 14px;
  }

  .SettingSec {
    font-size: 14px;
  }

  .mylink {
    font-size: 14px;
  }

  .mylink:hover {
    color: black;
  }

  .page {
    width: 88.5%;
  }

  .react-date-picker__inputGroup {
    width: 542px !important;
  }

  .mainhomescreen {
    gap: 0px;
  }

  .horizontal-scroll-wrapper.squares .myselecthorse {
    width: 207% !important;
  }

  .filtertextformresp>input {
    height: 42px;
    width: 200px !important;

    margin: 24px -15px !important;
  }

  .resultButton {
    width: 165px !important;
    margin: -92px 34px 0 0 !important;
    height: 56px !important;
  }
}

@media (max-width: 1600px) and (min-width: 1320px) {
  .resultsdata12 span {
    flex: 0 0 26% !important;
    margin-right: 7px !important;
  }

  .myselecthorsedata span {
    flex: 0 0 13.5% !important;
    margin: 0 !important;
  }

  .resultsdata1 span {
    flex: 0 0 15% !important;
    margin-right: 8px !important;
  }

  .filtertextform>select {
    margin: 24px;
    width: 220px;
  }

  button.filterbtn {
    margin-top: -64px;
  }

  .searchInputs>input {
    width: 220px !important;
    margin: 24px 18px !important;
    font-size: 12px !important;
  }

  .col-sm.formodal {
    display: none !important;
  }

  .horizontal-scroll-wrapper.squares {
    overflow-x: auto !important;
  }

  button.removebtnhorse {
    min-width: 95px;
  }

  .myselectiondataaddHorse2 {
    height: 100px;
  }

  .myselectiondataaddHorse1 {
    height: 200px;
  }

  button.savebtnhorse {
    min-width: 100px;
  }

  .accordion-button:not(.collapsed) {
    font-size: 13.5px;
  }

  .myselecthorsedata>span {
    padding: 18px !important;
    font-size: 12px !important;
  }

  .myselectiondataaddHorse>span input {
    width: 59px !important;
  }

  .filtertextform>input {
    width: 220px ;
  }

  .searchDropdown {
    width: 220px;
    margin: 24px 18px;
  }

  .react-date-picker__inputGroup {
    width: 413px !important;
  }

  .AccordionBody>div>.mylink {
    font-size: 13.5px;
  }

  .accordion-button {
    font-size: 13.5px;
  }

  .SettingSec {
    font-size: 13.5px;
  }

  .mylink {
    font-size: 13.5px;
  }

  .mylink:hover {
    color: black;
  }

  .page {
    width: 87.3%;
    margin-left: -15px;
  }

  .mainhomescreen {
    gap: 0px;
  }

  .logoutclass {
    width: 200px;
  }

  .buttonLogout {
    font-size: 15px;

    width: 126px;
  }

  .Header h4 {
    font-size: 16px;
  }

  .Header button {
    font-size: 13px;
  }

  .SubmitButton {
    font-size: 14px;
  }

  .div_maintb td:nth-child(1) {
    padding-right: 70px;
    font-size: 13px;
  }

  .Header h6 {
    font-size: 13px;
  }

  .filtertextform {
    padding: 0px;
    margin: 0 7px;
  }

  .userFilter {
    display: block;

  }

  .filterTextForm {
    display: flex !important;
    margin: 0 !important;
    flex-wrap: wrap;
    width: 80%;
  }

  .filterBtn {
    margin: 15px 71px 0 46px !important;
  }

  .css-b62m3t-container {
    width: 100% !important;
    font-size: 11px !important;
    line-height: 11px;
  }
}

@media (max-width: 1320px) and (min-width: 1024px) {
  .resultsdata1 span {
    flex: 0 0 15% !important;
    margin-right: 1px !important;
  }

  .formodal {
    display: none !important;
  }

  button.removebtnhorse {
    min-width: 95px;
  }

  .sbmtbtndiv {
    margin-right: 21px;
  }

  button.savebtnhorse {
    min-width: 100px;
  }

  .accordion-button:not(.collapsed) {
    font-size: 12.5px;
  }

  .filtertextform>input {
    width: 120px !important;
    font-size: 12px;
    margin-right: 0px !important;
  }

  button.filterbtn {
    margin: -90px -11px 0 46px;
    width: 160px;
    font-size: 14px;
  }

  .adminsidebar {
    width: 250px;
  }

  .mainhomescreen {
    gap: 0px;
  }

  .react-date-picker__inputGroup {
    width: 250px !important;
  }

  .DashboardCard {
    gap: 35px !important;
    margin: 1rem !important;
  }

  .newsErrorAr {
    float: right;
    margin-top: 70px !important;
    margin-right: 4px !important;
  }

  .AccordionBody>div>.mylink {
    font-size: 14px ;
  }

  .accordion-button {
    font-size: 14px !important;
  }

  .SettingSec {
    font-size: 14px !important;
  }

  .mylink {
    font-size: 14px !important;
  }

  .mylink {
    padding-left: 37px;
  }

  .mylink:hover {
    color: black;
  }

  .page {
    width: 85.5%;
    margin-left: -7px;
  }

  .logoutclass {
    width: 200px;
  }

  .buttonLogout {
    font-size: 15px;

    width: 126px;
  }

  .Header h4 {
    font-size: 16px;
  }

  .Header button {
    font-size: 13px;
  }

  .SubmitButton {
    font-size: 13px;
  }

  .div_maintb td:nth-child(1) {
    padding-right: 70px;
    font-size: 13px;
  }

  .logoclass {
    width: 234px;
  }

  td,
  th {
    font-size: 12px !important;
  }

  .sidebar {
    width: 190px;
    height: 100vh;
  }

  .Header h6 {
    font-size: 12px;
  }

  .rightsidedata {
    margin-left: -11px;
  }

  .Multipleownerlabel {
    font-size: 16px !important;
  }

  .RaceButtonDiv {
    margin-right: -23px;
  }

  .filtertextform {
    margin: 0 -18px;
  }

  .filtertextform>input {
    width: 180px !important;
  }

  .searchDropdown {
    width: 180px;
    margin: 24px 0px;
    margin-right: 10px;
  }

  span.myedit {
    width: 18% !important;
    margin-right: 40px !important;
  }

  .horizontal-scroll-wrapper.squares .myselecthorse {
    width: 247% !important;
  }

  .rdrDateRangePickerWrapper {
    width: 100% !important;
  }

  .userFilter {
    display: block;

  }

  .filterTextForm {
    display: flex !important;
    margin: 0 !important;
    flex-wrap: wrap;
    width: 80%;
  }

  .filterBtn {
    margin: -55px 46px 0 46px !important;

  }

  .searchInputs>input {
    margin: 24px;
    width: 212px !important;
    height: 40px;
    font-size: 12px;
  }


  button.filterBTN {
    margin: -87px -7px 0 46px !important;
    width: 160px;
    font-size: 14px;
  }

  .Header button {
    width: 153px !important;
    font-size: 13px !important;
  }
}