.Header {

    display: flex;
    justify-content: space-between;
    background-color: #E8E8E8;

    border-radius: 0px 20px 0px 20px;
    width: 100%;
    height: 78px;
    align-items: center;
    padding-left: 20px;
    padding-right: 30px;
    margin-left: 20px;
    margin-top: -35px;
}

.Header h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 15.5949px;
    line-height: 19px;
    padding-top: 7px;
    color: #000000;
    padding-left: 6px;
}

.Header button {
    background: linear-gradient(89.98deg, #19469D 23.67%, #00A3FF 97.94%);
    border-radius: 40px;
    width: 186px;
    height: 51px;
    color: white;
    border: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15.5949px;
    line-height: 19px;
    margin-top: 0px;
}

.Header h6 {
    cursor: pointer;
    padding-top: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
}

.Header>div {

    display: flex;
    align-items: center;
}

.div_maintb {
    /* height: calc(100vh - 180px); */
    width: calc(100v);
    overflow: scroll;
    margin-left: 20px;
    width: 101%;
    height: 75vh;
}

table {
    caption-side: bottom;
    border-collapse: inherit;
    width: 92vw;
}

tbody,
td,
tfoot,
th,
thead,
tr {
    border-color: #bfbcbc;
    border-style: solid;
    border-width: 1px 0px;
}

.div_maintb table {
    border-spacing: 0px 6px;
    margin-top: 10px;
}

.div_maintb th {
    position: sticky;
    top: 0;

    color: #d1d1d1;

    width: 100vw;
    padding: 6px;

    font-weight: normal;

    padding: 10px 35px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
}

.div_maintb td {

    padding: 10px 35px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
}

.div_maintb th {


    padding: 10px 35px;
}

.div_maintb th:nth-child(1),
.div_maintb td:nth-child(1) {
    position: sticky;
    background-color: #f1f1f1;
    left: 0;
    border-radius: 20px;
}



.div_maintb td:nth-child(1) {
    height: 58px;
    padding-left: 29px;


    padding-right: 100px;
}


.div_maintb th:nth-child(1) {
    z-index: 300;
}

thead {



    height: 58px;



}

.div_maintb th:nth-child(1) {
    width: 12%;
    background: linear-gradient(89.98deg, #19469D 23.67%, #00A3FF 97.94%);
    padding-left: 2rem;

}

.div_maintb th:nth-child(2) {

    padding-left: 3rem;
    border-radius: 20px 0 0 20px;
}


.TableData {

    overflow-x: auto;
    width: 900px;

}

thead {
    background: linear-gradient(274deg, #19469D 23.67%, #00A3FF 97.94%);
    border-radius: 20% !important;
    white-space: nowrap;
    width: 50px;
    overflow: hidden;
    border-radius: 20% !important;
    white-space: nowrap;
    width: 50px;
    overflow: hidden;
    text-overflow: "----";



}

tbody {
    padding-left: 100px;
    border-width: 2px 0px 2px 0px;
    /* background: #f1f1f1; */
    border-color: #cdcccc;
    white-space: nowrap;
    width: 50px;
    overflow: hidden;
    text-overflow: "----";


}


/* width */
.div_maintb::-webkit-scrollbar {
    width: 0px !important;
}

/* Track */
.div_maintb::-webkit-scrollbar-track {
    background: transparent !important;
}

.thirdChild {
    text-overflow: " [..]";
    white-space: nowrap;
    max-width: 400px;
}

/* Handle */
.div_maintb::-webkit-scrollbar-thumb {
    background: transparent !important;
}

/* Handle on hover */
.div_maintb::-webkit-scrollbar-thumb:hover {
    background: transparent !important;
}

.div_maintb:hover {
    cursor: grab;
    transition: all 200ms ease-in-out;
}


.newtables {
    border: 1px solid black;
}

.div_maint1 {
    background: linear-gradient(89.98deg, #19469D 23.67%, #00A3FF 97.94%);
    border-radius: 20px !important;

}

td.table_delete_btn1 {
    font-size: 20px;
}

td:last-child {
    /* color: #19469D; */
    text-align: inherit;
    font-size: 14px;
}

.div_maintb>td:last-child>svg {
    margin-left: 5px;
    font-size: 20px;
    cursor: pointer;
}

.div_maintb thead:last-child {

    text-align: center;

}



.div_maintb td:nth-child(2) {
    padding-left: 3rem;
    border-radius: 20px 0 0 20px;
}

.div_maintb td:last-child svg {
    vertical-align: middle;
    font-size: 18px;
    color: darkblue;
    margin-left: 18px;
}

.div_maintb th {
    padding: 10px 35px;
    position: relative !important;
    left: -35px !important;
}

.div_maintb th:nth-child(1),
.div_maintb td:nth-child(1) {
    left: 0 !important;
    border-radius: 0 20px 20px 0 !important;
    position: sticky !important;
    padding-right: 100px;
}

.div_maintb td {
    position: relative;
    left: -35px !important;
}

.div_maintb td:nth-child(1) {
    z-index: 2;
}

.div_maintb th:nth-child(2) {
    padding-left: 6rem ;
    border-radius: 20px 0 0 20px;
}

.div_maintb th {
    padding: 10px 65px;
    position: relative !important;
    left: -35px !important;
}

.div_maintb td:nth-child(2) {
    padding-left: 4rem !important;
    border-radius: 20px 0 0 20px;
}

.div_maintb td {
    padding: 10px 65px;
}

.div_maintb th:last-child {

    margin-right: 100px;
}

.nav-tabs .nav-link {
    margin-bottom: calc(var(--bs-nav-tabs-border-width) * -1);
    background: 0 0;
    border: var(--bs-nav-tabs-border-width) solid transparent;
    border-top-left-radius: var(--bs-nav-tabs-border-radius);
    border-top-right-radius: var(--bs-nav-tabs-border-radius);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    margin: 0 3px;
    height: 57px;
    color: rgba(0, 0, 0, 0.5);
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
}

.mb-3 {

    margin: 15px 15px 10px 17px;

}

.nav-tabs {
    border: none !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: var(--bs-nav-tabs-link-active-color);
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: var(--bs-nav-tabs-link-active-border-color);
    transition: all ease-in-out 0.5s;
    width: 247px;
    background: #00A3FF;
    color: white;
    height: 57px;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
}

.myselecthorsedata>span:nth-child(3) {
    /* width: 21%; */
    margin-left: 15px;
}

.myselecthorsedata>span:nth-child(5) {
    margin-left: 65px;
}

.myselecthorsedata>span:nth-child(4) {

    position: relative;
    left: 10px;
}

img.PreviewHorseImage {
    margin-top: 15px;
    margin-left: 20px;
    width: 150px;
    height: 150px;
}

.authclass {
    margin-top: -5px;
}

.css-26l3qy-menu {
    margin-top: 12px !important;
}


.checkbox-label {
    display: block;
    position: relative;
    margin: auto;
    cursor: pointer;
    font-size: 22px;
    line-height: 24px;
    height: 24px;
    width: 24px;
    clear: both;
}

.checkbox-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkbox-label .checkbox-custom {
    position: absolute;
    top: -2px;
    left: -46px;
    height: 57px;
    width: 111px;
    background-color: transparent;
    border-radius: 20px;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    /* border: 2px solid #FFFFFF; */
}


.checkbox-label input:checked~.checkbox-custom {
    background-color: #00A3FF;
    border-radius: 20px;
    -webkit-transform: rotate(0deg) scale(1);
    -ms-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    opacity: 1;
    /* border: 2px solid #FFFFFF; */
}


.checkbox-label .checkbox-custom::after {
    position: absolute;
    content: "";
    left: 12px;
    top: 12px;
    height: 0px;
    width: 0px;
    border-radius: 5px;
    border: solid #009BFF;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(0deg) scale(0);
    -ms-transform: rotate(0deg) scale(0);
    transform: rotate(0deg) scale(0);
    opacity: 1;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
}


.checkbox-label input:checked~.checkbox-custom::after {
    transform: rotate(45deg) scale(1);
    opacity: 1;
    left: 50px;
    top: 20px;
    width: 8px;
    height: 12px;
    border: solid #f5f5f5;
    border-width: 0 2px 2px 0;
    background-color: transparent;
    border-radius: 0;
}

.deletedDropdown {
    position: absolute;
    right: 214px;
    top: 120px;
}

.btn-primary {
    --bs-btn-color: black !important;
    --bs-btn-bg: transparent !important;
    --bs-btn-border-color: transparent !important;
    --bs-btn-hover-color: black;
    --bs-btn-hover-bg: transparent !important;
    --bs-btn-hover-border-color: transparent !important;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: transparent !important;
    --bs-btn-active-border-color: #0a53be;
    --bs-btn-active-shadow: inset 0 3px 5pxrgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #0d6efd;
    --bs-btn-disabled-border-color: #0d6efd;
}

.dropdown-menu.show {
    display: block;
    height: 330px;
    overflow-y: auto;
}

td.table_delete_btn1 svg {
    margin: 0 4px;
}

.imageRow img {
    background-image: url('../../assets/Placeholder.jpg');
    width: 100px;
    height: 50px;
}

/* .spanfordropdown {
    width: 100px !important;
}


.filtertextform input,
select {
    border: none;
    WIDTH: 386px;
}

.searchDropdown {
    border: none;
    border-bottom: 2px solid !important;
    margin-left: 22px;
    width: 184%;
    margin-top: 20px;
} */

.searchDropdown1 {
    margin-left: 27px !important;

}

.noDataPlaceHolder {
    display: flex;
    align-items: center;
    justify-content: center;


}

.noDataPlaceHolder img {
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 34%;
    top: 27%;
}

.searchInputs>input {
    margin: 24px;
    width: 295px;
    height: 42px;
}

.changeStatusbtn {
    width: 70px;
    font-size: 10px;
    margin: 0 2px;
    border-radius: 5px;
    border: none;
}