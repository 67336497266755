body {
  overflow: hidden;
}

.loginheader {
  border-radius: 0px 0px 50px 50px;
}

.SettingSec {
  border-top: unset !important;
}

.themesetting {
  width: 100%;
  height: auto !important;
  margin: 30px;
}

.mylink {
  padding-left: 35px;
  font-weight: 600;
  font-size: 18.5949px;
  line-height: 19px;
  padding-top: 30px;
}

.accordion-button {
  padding-left: 37px !important;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 22px;
  padding-bottom: 13px;
}

.accordion-button::after {
  width: 10px;
  height: 10px;
  background-position: bottom;
}

.AccordionBody > div > .mylink {
  font-size: 14px !important;
}

li.page-item {
  margin: 0 5px;
}

button.page-item {
  border: 1px solid rgb(156, 152, 152);
  border-radius: 20px;
  background-color: rgb(156, 152, 152);
  color: #fff !important;
  padding: 8px 15px !important;
  transition: all 300ms ease-in-out !important;
}

button.page-item:hover {
  border: 1px solid #19469d !important;
  background-color: #19469d;
  transition: all 300ms ease-in-out !important;
}

button.page-item1 {
  border: 1px solid #19469d !important;
  border-radius: 20px;
  background-color: #19469d;
  color: #fff !important;
  padding: 8px 15px !important;
  transition: all 300ms ease-in-out !important;
}

.pagination {
  margin-top: -6px;
}

td.table_delete_btn1 svg:hover {
  transition: all 300ms ease-in-out !important;
  color: #19469d;
}

td.table_delete_btn1 svg {
  color: #fff !important;
  background: #2b2929;
  /* padding: 0px 20px; */
  width: 28px;
  height: 25px;
  padding: 4px;
  border-radius: 10px;
}

td.table_delete_btn1 svg:hover {
  color: #fff;
  background: #19469d;
  /* padding: 0px 20px; */
  width: 30px;
  height: 25px;
  padding: 4px;
  border-radius: 10px;
}

th {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.SubmitButton:hover {
  background: linear-gradient(89.98deg, #00a3ff 23.67%, #19469d 97.94%);
  transition: all 200ms ease-in-out !important;
}

.SubmitButton {
  transition: all 200ms ease-in-out !important;
}

.form-floating > label {
  top: -5px !important;
}

.error {
  font-size: 10px !important;
  position: absolute;
  top: 50px;
  border: 1px solid;
  padding: 0px 10px !important;
  background: red !important;
  color: #fff !important;
  border-radius: 7px !important;
}

.errorAr {
  font-size: 10px !important;
  position: absolute;
  top: 50px;
  border: 1px solid;
  padding: 0px 5px !important;
  background: red !important;
  color: #fff !important;
  border-radius: 7px !important;
}

label.Multipleownerlabel {
  box-shadow: unset !important;
}

form .addmore {
  position: relative;
  background: transparent;
  border: none;
  color: lightgray;
  margin-right: 50px;
  margin-top: 23px;
  cursor: pointer;
  padding: 0px 0px 0px 6px !important;
  font-size: 16px;
}

form .addmore:nth-child(1) {
  margin-right: 10px;
  margin-top: 23px;
  font-size: 19px;
  padding: 0px 10px !important;
}

form .addmore:hover {
  transition: all 200ms ease-in-out !important;
  color: #fff !important;
}

form span.addmore {
  background: #19469d;
  border-radius: 10px;
  padding: 2px 7px !important;
}

.modal-lg,
.modal-xl {
  --bs-modal-width: 900px;
}

.ButtonSection {
  align-items: end;
}

.modal-body .Headers {
  text-align: center;
  font-size: 25px;
}

.div_maintb table tbody td:nth-child(2) {
  padding-left: 6rem !important;
  border-radius: 20px 0 0 20px;
}

.tr_table_class td img {
  width: 40px !important;
}

.AccordionBody > div > .mylink:active {
  font-weight: 700;
}

.swal-icon--warning {
  border-color: #19469d !important;
}

.swal-icon--warning__body,
.swal-icon--warning__dot {
  background-color: #19469d !important;
}

.swal-footer {
  text-align: center;
}

.swal-button--danger {
  background-color: #19469d;
}

.swal-button--danger:hover {
  background-color: #19469d !important;
}

.swal-button {
  background-color: #19469d;
  color: #fff;
  border-color: #19469d;
}

.swal-button:hover {
  background-color: #19469d !important;
  color: #fff;
  border-color: #19469d;
}

.Header h4 {
  font-size: 25px !important;
}

.rightsidedata div.Headers {
  font-size: 25px ;
}

.modal-body .rightsidedata {
  height: auto !important;
}

span.ViewCalulatedPrize111 label:hover {
  box-shadow: unset !important;
}

.css-b62m3t-container {
  width: 94% !important;
}

input#custom-switch {
  height: 25px !important;
  width: 45px !important;
  margin-top: 10px !important;
}

td:nth-child(1) {
  /* color: #fff !important; */
  font-weight: 700 !important;
}

table button.Approvedbtn.resultbtn {
  color: #fff !important;
  font-weight: 500 !important;
  background: #19469d !important;
}

table button.Approvedbtn.resultbtn {
  color: #000;
  margin-left: 0px !important;
}

.div_maintb td:last-child svg {
  color: #fff !important;
  margin-left: 2px !important;
}

.userlists td:nth-child(1) {
  color: #000 !important;
  font-weight: 700 !important;
}

.btn.show {
  background: #19469d !important;
  border-color: #19469d !important;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: unset !important;
}

.react-date-picker__calendar .react-calendar {
  border-radius: 15px;
}

.react-calendar__navigation button:disabled {
  background-color: #19469d !important;
  /* border-radius: 10px; */
  color: #fff;
  font-size: 16px !important;
  margin-left: 4px;
  margin-right: 0px !important;
  cursor: no-drop;
}

.react-calendar__navigation button:disabled:nth-child(1) {
  border-radius: 10px;
}

.react-calendar__navigation button {
  background-color: #19469d !important;
  border-radius: 10px;
  color: #fff;
  font-size: 16px !important;
  margin-right: 4px !important;
}

.react-calendar__tile--now {
  background: #19469d !important;
  color: #fff !important;
  font-weight: 800 !important;
}

.SubmitButton:disabled {
  backface-visibility: rgb(212, 212, 209);
}

.react-calendar__tile:disabled {
  background-color: #19469d26;
  color: #000;
  cursor: no-drop;
  border-radius: 15px;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000 !important;
}

.react-calendar__tile--active {
  background: #19469d;
  color: white;
  font-weight: 800 !important;
  border-radius: 15px;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #19469d !important;
  color: #fff !important;
  font-weight: 800 !important;
  border-radius: 15px;
}

.react-calendar {
  width: 60% !important;
}

.resulttopheader input {
  border-radius: 10px;
  padding: 10px;
}

.resulttopheader input:focus {
  border-color: #19469d;
}

.resulttopheader .filtertextform > input {
  width: 200px;
  border: 2px solid #ddd;
}

button.Approvedbtn.resultbtn.raceimagebtn {
  float: right;
  margin: 10px 0 !important;
  background: linear-gradient(89.98deg, #19469d 23.67%, #00a3ff 97.94%);
  border-radius: 40px;
  width: 160px;
  height: 50px;
  color: white;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15.5949px;
  line-height: 19px;
}

.Prizeclass td:nth-child(1) {
  color: #000 !important;
  font-weight: 700 !important;
}

.Prizeclass td {
  padding: 10px 0;
}

button.savebtnhorse {
  height: 25px !important;
  width: 40px !important;
  min-width: 40px !important;
}

button.removebtnhorse {
  height: 25px !important;
  width: 40px !important;
  min-width: 40px !important;
}

button.removebtnhorse svg {
  width: 10px !important;
  margin-top: -5px !important;
}

.savebtnhorse svg {
  width: 20px !important;
  margin-top: -5px !important;
  margin-right: 3px;
}

.myselecthorsedata span {
  margin-right: 0px !important;
  left: 0 !important;
  width: 20% !important;
  margin-left: 0 !important;
  display: inline-flex !important;
  flex: 0 0 7.9% !important;
}

.horizontal-scroll-wrapper.squares .myselecthorse {
  width: 180% !important;
}

/* span.myedit {
    border: unset !important;
} */
.myselectiondataaddHorse1 {
  height: 300px !important;
  width: 98% !important;
}

.myselecthorsedata {
  justify-content: flex-start !important;
  width: 100%;
}

span.myedit {
  margin-right: 55px !important;
  left: 0 !important;
  width: 13% !important;
  margin-left: 0 !important;
  display: inline-flex !important;
  flex: 0 0 10% !important;
  max-width: 50% !important;
}

.horizontal-scroll-wrapper.squares .myselecthorsedata > span:nth-child(5) {
  margin-left: 0px !important;
}

.myselectiondataaddHorse {
  display: flex !important;
  margin-left: 17px !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: 100% !important;
}

.css-b62m3t-container {
  width: 100% !important;
}

.myedit .InputAddhorse5 {
  min-width: 180px !important;
}

.myselectiondataaddHorse > span:last-child {
  border: unset !important;
  justify-content: center;
}

/* .myselectiondataaddHorse>span:nth-last-child(3){
    border: unset !important;
} */
.myselectiondataaddHorse > span:nth-last-child(2) {
  border: unset !important;
  justify-content: center;
  margin-right: 10px !important;
}

.myselecthorsedata span.myedit:nth-last-child(1) {
  flex: 0 0 7% !important;
}

.myselectdata form {
  align-items: flex-start !important;
}

.myselectdata hr {
  display: none !important;
}

.mynew > span {
  flex: 2 1;
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  margin-top: 18px;
  margin-right: 1px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  height: 55px;
  width: 116px;
  margin-right: 30px;
  flex: 0 0 12% !important;
  max-width: unset !important;
  min-width: unset !important;
  margin-right: 25px !important;
}

.mynew .form-switch {
  padding: -0.5em;
  border: none;
  margin: 0px 0px 0p;
  font-size: 25px;
  /* margin-left: 60px; */
  margin-top: 7px;
  flex: 0 0 12%;
  margin-right: 27px;
  margin-left: 12px;
  align-items: center;
  padding-top: 15px;
}

.mynew button.savebtnhorse {
  margin-top: 25px !important;
}

span.myedit:last-child {
  margin-right: 50px !important;
}

.horizontal-scroll-wrapper.squares .myselecthorse.editver {
  width: 135% !important;
}

.horizontal-scroll-wrapper.squares .myselecthorse.editver span {
  margin-right: 0px !important;
  left: 0 !important;
  width: 20% !important;
  margin-left: 0 !important;
  display: inline-flex !important;
  flex: 0 0 18% !important;
}

.myselectiondata.myselectiondataaddHorse2.editver span {
  flex: 0 0 19%;
  margin-right: 70px;
}

.css-1u9des2-indicatorSeparator {
  background-color: transparent !important;
}

.editver .css-b62m3t-container {
  position: relative;
  box-sizing: border-box;
  top: 15px;
  left: 0;
  width: 90% !important;
}

.editver button.savebtnhorse {
  height: 30px !important;
  width: 40px !important;
  min-width: 50px !important;
}

.editver button.removebtnhorse {
  height: 30px !important;
  width: 40px !important;
  min-width: 70px !important;
  margin-left: 5px;
}

.edditver .horizontal-scroll-wrapper.squares {
  overflow-x: auto !important;
  height: 60vh;
}

.myselectiondata.myselectiondataaddHorse2.editver {
  height: 65px !important;
}

.myselectdata.myaddver span {
  margin-right: 50px;
  flex: 0 0 20%;
}

.myselectdata.myaddver span:last-child {
  margin-right: 50px;
  flex: 0 0 10%;
  display: flex;
  justify-content: center;
}

.myselectdata.myaddver span:nth-last-child(2) input[type="text"] {
  width: 100% !important;
  border: 1px solid #efefef !important;
  padding: 15px 15px;
  border-radius: 20px;
  margin-top: 2px !important;
}

.myselectdata.myaddver .css-13cymwt-control {
  width: 90%;
}

.myselectdata.myaddver .css-13cymwt-control {
  width: 90% !important;
}

table.calcualtedPrizeTable td:nth-child(1) {
  color: #000 !important;
  font-weight: 700 !important;
}

.myselecthorse.myresult .myselecthorsedata span {
  flex: 0 0 12.5% !important;
  margin: 0 !important;
}

.myselectiondata span.spanfordropdown {
  min-width: unset !important;
}

.myselectdata.resultsdata span {
  flex: 0 0 10% !important;
}

.myselectdata.resultsdata span {
  flex: 0 0 12% !important;
  margin-right: 40px;
}

.myselectdata.resultsdata span:last-child {
  border: unset !important;
  display: flex;
}

.myselectdata.myaddmore span {
  flex: 0 0 12%;
  margin-right: 45px;
}

.myselectdata.myaddmore span:last-child {
  margin-left: 40px;
}

.success {
  color: #fff;
  font-size: 11px;
  position: absolute;
  top: 50px;
  background: #228b22;
  padding: 0 5px;
  border-radius: 5px;
  font-size: 10px !important;
}

.successAr {
  color: #fff;
  font-size: 11px;
  position: absolute;
  top: 50px;
  background: #228b22;
  padding: 0 5px;
  border-radius: 5px;
  font-size: 10px !important;
}

span.CodeMsg {
  color: #fff;
  font-size: 11px;
  position: absolute;
  top: 50px;
  background: #228b22;
  padding: 0 5px;
  border-radius: 5px;
  font-size: 10px !important;
}

.CodeMsger {
  color: #fff;
  font-size: 11px;
  position: absolute;
  top: 50px;
  background: red;
  padding: 0 5px;
  border-radius: 5px;
  font-size: 10px !important;
}

.verifybtn {
  background: linear-gradient(89.98deg, #19469d 23.67%, #00a3ff 97.94%);
    color: #fff !important;
    padding: 5px 5px;
    margin-top: 0px !important;
    border: unset !important;
    font-size: 12px;
}

.css-b62m3t-container .css-1s2u09g-control {
  width: 100% !important;
}

.css-yt9ioa-option {
  background-color: #fff !important;
}

.ButtonSection {
  align-items: flex-start;
}

.PreviewImage {
  width: 5rem !important;
  height: 4rem !important;
  margin: 20px;
  border-radius: 20px;
  object-fit: cover;
}

.ButtonSection div:nth-child(2) {
  padding-top: 35px;
}

.ButtonSection div:first-child {
  padding-top: 5px;
}

.react-date-picker__inputGroup {
  padding: 6px !important;
}

.addmorecomp {
  margin-right: 11px !important;
  width: 31px !important;
  font-size: 17px !important;
}

.pointsAdd .css-6j8wv5-Input {
  padding-top: 18px !important;
}

.rightsidedata input.dateforraces {
  margin-top: 1px;
  width: 159px !important;
  align-items: center;
  margin: auto;
}

.rightsidedata input.dateforrace {
  margin-top: 1px;
  width: 197px;
  align-items: center;
  margin-left: 20px !important;
  margin-top: 2px !important;
  width: 95% !important;
}

span.transitionclass {
  display: inline-flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
}

.newsSuccess {
  color: #fff;
  font-size: 11px;
  background: #228b22;
  padding: 2px 5px;
  top: 10px;
  border-radius: 5px;
  font-size: 10px !important;
}

.newsSuccessAr {
  color: #fff;
  font-size: 11px;
  background: #228b22;
  padding: 2px 5px;
  top: 10px;
  border-radius: 5px;
  font-size: 10px !important;
  margin-top: 10px !important;
}

.editformtable thead {
  border: unset !important;
  background: linear-gradient(274deg, #19469d 23.67%, #00a3ff 97.94%);
}

.editformtable th {
  border: unset !important;
  padding: 10px 80px;
  color: #fff !important;
  text-align: center;
}

.editformtable tr {
  border: unset !important;
}

table.editformtable {
  border-collapse: collapse !important;
  margin-top: 25px;
  width: 100%;
}

table.editformtable input {
  border: unset !important;
  text-align: center;
}

table.editformtable td {
  border: unset !important;
  text-align: center;
}

table.editformtable .dropdown.multidropdown.css-b62m3t-container {
  border: 1px solid #efefef !important;
  margin: 10px;
  width: 90% !important;
}

table.editformtable .spanForm1 {
  margin-top: -70px !important;
  margin-right: -25px !important;
}

table.editformtable tbody {
  border-color: #efefef !important;
}

table.editformtable td {
  border: unset !important;
  text-align: center;
  vertical-align: baseline;
}
   
table.editformtable .bgtable span {
  border: 1px solid #efefef !important;
  padding: 10px;
  width: 100% !important;
  border-radius: 20px;
  background: #efefef;
}

table.editformtable .bgtable input {
  background: #efefef;
}
.registrationform > form {
  width: 500px;
  margin: auto;
  height: auto !important;
  padding: 50px 0 !important;
}
h3.WelcomeAdmin {
  padding: 0;
  padding-bottom: 20px;
  text-transform: uppercase !important;
  font-size: 32px;
  font-weight: 700;
}
.registrationform input.form-input {
  padding: 25px !important;
}
.showIcon {
  margin-top: -45px !important;
}
button.buttonRegister:hover {
  background: #0889e3;
  color: #fff;
  transition: all 300ms ease-in-out;
}
.mainlogin {
  height: 100vh;
  background: url(https://upload.wikimedia.org/wikipedia/commons/4/48/GGF_Race5.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.mainlogin:before {
  content: "";
  position: absolute;
  z-index: 9999;
  background: #0000007d;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.loginheader {
  position: relative;
  z-index: 9999999;
}
.registrationform {
  position: relative;
  z-index: 9999999;
  border-radius: 70px !important;
}
.loginheader {
  width: 30% !important;
}
.loginheader > H2 {
  font-weight: 800 !important;
}
.DashboardCard div {
  width: 23% !important;
}
.DashboardCard {
  gap: 25px !important;
  margin-left: 0 !important;
}
.ButtonSection {
  margin-top: 2rem !important;
}
input:focus {
  box-shadow: unset !important;
  border: unset;
}
.ant-form-item-label {
  align-items: center;
  display: flex !important;
}
.ant-form-item {
  margin: 0 !important;
}
.col-sm {
  margin: 5px !important;
}
.spacer {
  margin-top: -50px !important;
  position: absolute;
  right: 0;
  z-index: 99999;
}
.spanForm {
  margin-top: -50px !important;
}
table.calcualtedPrizeTable td {
    text-align: center;
}
.ButtonSection {
  align-items: end !important;
}
.css-b62m3t-container {
  margin-top: -8px;
}
.ant-select.ant-select-in-form-item {
  width: 93%;
  height: auto !important;
}
.ant-select-selector {
    box-shadow: unset !important;
}
span.addmore.addmore1111222 {
  margin-right: 38px !important;
  /* height: 100%; */
}
table.ant-picker-content th {
    color: #fff !important;
}
.ButtonSection input {
    padding-left: 20px !important;
}
.ant-select.ant-select-in-form-item {
  width: 90% !important;
  height: 45px !important;
  display: flex !important;
  align-items: center !important;
}
.spanForm.selectSpacer {
  margin-top: -60px !important;
}
td {
    text-transform: capitalize;
}

input#time_related_controls_date-picker {
    border: unset !important;
}
.ant-picker {
    border: unset !important;
    margin: 0 !important;
    box-shadow: unset !important;
}
.col-sm.ages {
    justify-content: end;
    align-items: center;
}
.col-sm.ages p{
    margin: 0;
    padding-right: 5px;
    font-weight: 700;
}
.pointsAdd input {
    height: 50px !important;
}
.pointsDropdown input{
    height: 10px !important;
}
.pointsDropdown input {
    height: 10px !important;
}
span.spanForm.spanForm1.aaaa1 {
    margin-top: -73px !important;
}
.rightsidedata {
  margin-top: 10px !important;
}

.header {
  height: 70px !important; 
}
.logoclass {
  height: 70px !important;
}

.auth {
  height: 70px !important;
}
.logoutclass {
  height: 70px !important;
}
.buttonLogout {
  margin-top: 15px !important;
}
.rightsidedata {
  margin-top: 10px !important;
}
.adminsidebar {
  margin-top: 10px !important;

}

.Headers {
margin-bottom: 15px !important;  
height: 70px !important;
}
thead {
  height: 50px !important;
} 
.mainrow {
  margin-top: 10px !important;
}

.div_maintb td:nth-child(1) {
  height: 55px !important;
}

.Header {
  height: 65px !important;
}

.mainhomescreen {
  gap: 5px !important;
}
input#file {
  width: 200px;
}
.edit .spanForm {
left: 85% !important;
margin-top: -60px !important;
}

.deletedDropdown {
  top: 90px !important;
}

.ant-form-item-explain-error {
  position: absolute;
  bottom: -5px;
  background: red;
  color: #fff !important;
  padding: 0 15px;
  border-radius: 10px;
  font-size: 10px;
}
.editsilkcolorimg button {
  font-size: 14px;
  padding: 5px 10px;
}
@media only screen and (max-width: 1550px) {
  .myaddmore .css-b62m3t-container {
    width: 100% !important;
    font-size: 11px !important;
    line-height: 11px;
  }
  .filtertextform>input {
    width: 190px !important;
}
  .deletedDropdown {
    top: 90px !important;
  }
  .pagination {
    margin-top: -25px !important;
}
  .loginheader {
    width: 40% !important;
  }
  .fetchTrack {
    right: 8%;
}
  .div_maintb {
    height: 71vh !important;
    margin-bottom: 15px !important;
}
.div_maintb th:nth-child(1), .div_maintb td:nth-child(1) {
    padding-right: 130px !IMPORTANT;
}
.div_maintb th:nth-child(2) {
    padding-left: 4rem !important;
}
td.table_delete_btn1 svg {
    width: 22px;
    height: 22px;
}
  button.filterbtn {
    margin-top: -70px !important;
  }
  .div_maintb table tbody td:nth-child(2) {
    padding-left: 4rem !important;
}
  .searchDropdown {
    width: 210px !important;
    margin: 24px 10px !important;
  }

  .searchDropdown .css-14el2xx-placeholder {
    width: 115px !important;
  }

  .results {
    align-items: center;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
  }

  span.css-1u9des2-indicatorSeparator {
    margin: 0 !important;
  }

  .myselectdata.myaddmore span {
    flex: 0 0 12.2%;
    margin-right: 32px;
  }

  .myselectdata.myaddmore span:last-child {
    margin-left: 0px;
  }

  .myselectdata.myaddmore span:nth-child(12) {
    margin-left: 20px;
    margin-right: 15px !important;
  }

  .css-b62m3t-container {
    width: 75% !important;
    font-size: 11px !important;
    line-height: 11px;
  }

  span.myedit {
    width: 14% !important;
    margin-right: 40px !important;
  }

  .myselectiondataaddHorse > span:nth-last-child(2) {
    border: unset !important;
    justify-content: center;
    margin-right: 30px !important;
  }

  .mynew > span {
    margin-right: 19px !important;
  }

  .myselectiondata.myselectiondataaddHorse2.editver span {
    flex: 0 0 19%;
    margin-right: 50px;
  }

  .myselectdata.myaddver span {
    margin-right: 35px;
    flex: 0 0 20%;
  }

  .myselectdata.myaddver .css-b62m3t-container {
    width: 95% !important;
    font-size: 11px !important;
    line-height: 11px;
  }

  .react-calendar {
    width: 100% !important;
  }

  .myselectdata.resultsdata span {
    flex: 0 0 15% !important;
    margin-right: 32px;
  }

  .resultsdata .css-b62m3t-container {
    width: 100% !important;
    font-size: 11px !important;
    line-height: 11px;
  }

  .resulttopheader .filtertextform > input {
    width: 390px !important;
    margin-right: 17px !important;
    margin: 10px 0px;
  }

  .resulttopheader .filtertextform {
    padding: 20px 0 !important;
    margin: 0 10px;
  }

  .resulttopheader button.filterbtn {
    margin-top: -85px !important;
  }

  .userfilter {
    display: flex;
    align-items: center;
  }

  .ButtonSection div:nth-child(2) {
    padding-top: 33px;
  }

  .pointsAdd .css-6j8wv5-Input {
    padding-top: 22px !important;
  }

  .filtertextform > select {
    margin: 24px 12px !important;
    width: 200px !important;
  }

  .userfilter.userFilter .filtertextform > input {
    margin: 24px 12px !important;
    width: 200px !important;
    height: 42px;
  }

  .userfilter.userFilter .myuser.filtertextform > input {
    margin: 24px 12px !important;
    width: 180px !important;
    height: 42px;
  }
  button.accordion-button {
    padding-left: 25px !important;
  }
  a.mylink {
    padding-left: 25px !important;
  }
  button.accordion-button {
    padding-bottom: 10px !important;
    font-size: 13px !important;
    font-weight: 500 !important;
  }
  .AccordionBody > div {
    padding: 3px !important;
    padding-left: 0 !important;
  }
  .AccordionBody > div > .mylink {
    font-size: 13px !important;
  }
  .sidebar {
    padding-top: 10px;
  }
  .accordion-button::after {
    width: 25px !important;
  }
  .DashboardCard {
    margin-left: 0 !important;
  }
  .DashboardCard div {
    height: 200px;
    width: 31% !important;
  }
  .DashboardCard {
    gap: 25px !important;
  }

  .spacer {
    margin-top: -50px !important;
    position: absolute;
    right: 0;
    z-index: 99999;
  }
  span.spanForm.spacer{
     margin-top: -50px !important;
  }
  .Approvedbtn {
    border: none;
    font-size: 12px !important;
    color: #fff;
    height: 30px;
    border-radius: 20px;
    width: 95px !important;
    margin: auto !important;
}
.edit .spanForm {
  left: 81% !important;
  margin-top: -60px !important;
}
}

@media only screen and (max-width: 1440px) {
  .horizontal-scroll-wrapper.squares .myselecthorse {
    width: 182% !important;
  }
  .deletedDropdown {
    top: 90px !important;
  }
  .filtertextform>input {
    width: 190px !important;
}
  .fetchTrack {
    right: 9%;
}
.rightsidedata {
  margin-top: 10px !important;
}

.header {
  height: 70px !important; 
}
.logoclass {
  height: 70px !important;
}

.auth {
  height: 70px !important;
}
.logoutclass {
  height: 70px !important;
}
.buttonLogout {
  margin-top: 15px !important;
}
.rightsidedata {
  margin-top: 10px !important;
}
.adminsidebar {
  margin-top: 10px !important;

}

.Headers {
margin-bottom: 15px !important;  
height: 70px !important;
}
thead {
  height: 50px !important;
} 
.mainrow {
  margin-top: 10px !important;
}

.div_maintb td:nth-child(1) {
  height: 55px !important;
}

.Header {
  height: 65px !important;
}

.mainhomescreen {
  gap: 5px !important;
}
  .ButtonSection div:nth-child(2) {
    padding-top: 33px;
  }

  .filtertextform > select {
    margin: 24px 12px !important;
    width: 180px !important;
  }

  .filtertextform > input {
    width: 200px !important;
  }

  .results {
    align-items: center;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
  }

  span.InputAddhorse5 input {
    font-size: 12px;
    width: 85px !important;
  }

  .css-b62m3t-container {
    width: 100% !important;
    font-size: 11px !important;
    line-height: 11px;
  }

  .react-calendar {
    width: 100% !important;
  }

  .resulttopheader .filtertextform > input {
    width: 345px !important;
    margin-right: 17px !important;
    margin: 10px 0px;
  }

  .resulttopheader .filtertextform {
    padding: 20px 0 !important;
    margin: 0 10px;
  }

  .resulttopheader button.filterbtn {
    margin-top: -85px !important;
  }

  .userfilter {
    display: flex;
    align-items: center;
  }

  .DashboardCard div {
    width: 330px;
  }

  .userfilter .searchDropdown.searchDropdown1 {
    width: 270px;
    margin: 24px 0px;
    margin-right: 10px;
  }

  .addmorecomp {
    margin-right: 11px !important;
    width: 30px !important;
    font-size: 16px !important;
  }

  .css-tlfecz-indicatorContainer {
    padding: 5px 5px !important;
  }

  button.filterbtn {
    margin-top: -67px !important;
  }

  .userfilter.userFilter .myuser.filtertextform > input {
    margin: 24px 10px !important;
    width: 160px !important;
    height: 42px;
  }

  .spacer {
    margin-top: -50px !important;
    position: absolute;
    right: 0;
    z-index: 99999;
  }
}

@media only screen and (max-width: 1370px) {
  .horizontal-scroll-wrapper.squares .myselecthorse {
    width: 185% !important;
  }
  .deletedDropdown {
    top: 90px !important;
  }

  .filtertextform>input {
    width: 190px !important;
}

  .DashboardCard {
    margin: 1rem !important;
}

.rightsidedata {
  margin-top: 10px !important;
}

.header {
  height: 50px !important; 
  margin-top: 25px;
}
.logoclass {
  height: 50px !important;
  margin: 0px;
  padding: 0px;
  width: 185px !important;
  margin-top:-16px !important;
}

.auth {
  height: 70px !important;
}
.logoutclass {
  height: 50px !important;
  width: 185px !important;
}

.sidebar {
  width: 185px !important;
}
button.accordion-button {
  padding-bottom: 3px !important;
}
.topheading {
  padding: 12px 20px 0px 20px !important;
}

.dashboardheader {
  height: 60px !important;
}


.buttonLogout {
  font-size: 14px !important;
  margin-top: 20px !important;
  width: 100px !important;
  height: 25px !important;
}
.buttonLogout {
  margin-top: 15px !important;
}
.rightsidedata {
  margin-top: 10px !important;
}
.adminsidebar {
  margin-top: 10px !important;

}

.Headers {
margin-bottom: 15px !important;  
height: 70px !important;
font-size: 20px !important;
padding-left: 15px !important;

}
thead {
  height: 50px !important;
} 
.mainrow {
  margin-top: 10px !important;
  height: 55px !important;
}

.div_maintb td:nth-child(1) {
  height: 45px !important;
}
.div_maintb td {
  padding: 4px 65px !important;
  font-size: 13px !important;
}
.Header {
  height: 55px !important;
}
.Approvedbtn{
  width: 75px !important;
  height: 22px !important;
}
.mainhomescreen {
  gap: 5px !important;
}
  .searchDropdown {
    width: 190px !important;
    margin: 24px 10px !important;
  }
  .Header h4 {
    font-size: 20px !important;
    padding-top: 12px !important;
}
  .searchDropdown .css-14el2xx-placeholder {
    width: 65px !important;
  }
  label.ant-form-item-required {
    font-size: 12px !important;
}
.div_maintb td:nth-child(1) {
  padding-right: 95px !important;
}
.ButtonSection.btnsectionbtn {
  margin-top: -8rem !important;
}
.SubmitButton {
  font-size: 16px;
  font-weight: 800;
}
.Multipleownerlabel {
  padding: 0px 0px 10px 25px !important;
}

:where(.css-dev-only-do-not-override-1jr9qlj).ant-form label {
  font-size: 13px !important;
}
.Header button {
  width: 150px !important;
  height: 40px !important;
  font-weight: 600 !important;
  font-size: 13.5949px !important;
}
.swatch input[type="color"] {
  width: 12em !important;
}
  .userfilter.userFilter .filtertextform > input {
    margin: 24px 12px !important;
    width: 188px !important;
    height: 42px;
  }
  .swatch {
    width: 12.1em !important;
    height: 7em !important;
}
  .results {
    align-items: center;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
  }

  span.InputAddhorse5 input {
    font-size: 12px;
    width: 85px !important;
  }

  .mynew > span {
    margin-right: 22px !important;
  }

  .myselectdata.myaddver span {
    margin-right: 30px;
    flex: 0 0 20%;
  }

  .react-calendar {
    width: 100% !important;
  }

  .accordion-button::after {
    width: 35px;
    height: 10px;
    background-position: bottom;
  }
  .css-b62m3t-container {
    margin-top: -13px !important;
}
  .myselectdata.resultsdata span {
    flex: 0 0 15% !important;
    margin-right: 28px;
  }

  .resulttopheader .filtertextform > input {
    width: 200px !important;
    margin-right: 0px !important;
    margin: 10px 5px;
}

.resulttopheader .filtertextform {
  padding: 5px 0 !important;
  margin: 0 10px;
}
button.Approvedbtn.resultbtn.raceimagebtn {
  margin: auto;
  width: 200px !important;
  padding: 4px 7px;
  height: 40px !important;
}
  .resulttopheader button.filterbtn {
    margin-top: -85px !important;
  }

  .userfilter {
    display: flex;
    align-items: center;
  }

  .DashboardCard div {
    width:28% !important;
    height: 200px;
  }

  .DashboardCard div p {
    font-size: 22px;
  }
  .filterTextForm {
    margin: 0px 20px !important;
  
}
  .SettingSec1 a {
    font-size: 14px !important;
}
.emailcontent {
  width: 90% !important;
}
  .addmorecomp {
    margin-right: 11px !important;
    width: 30px !important;
    font-size: 16px !important;
  }

  button.filterbtn {
    margin-top: -67px !important;
    width: 150px !important;
    height: 40px !important;
  }

  .userfilter.userFilter .myuser.filtertextform > input {
    margin: 24px 12px !important;
    width: 140px !important;
    height: 42px;
  }
  .spacer {
    margin-top: -50px !important;
    position: absolute;
    right: 0;
    z-index: 999;
  }
}

@media only screen and (max-width: 1280px) {
  .horizontal-scroll-wrapper.squares .myselecthorse {
    width: 190% !important;
  }
  .deletedDropdown {
    top: 90px !important;
  }
  .spacer {
    margin-top: -50px !important;
    position: absolute;
    right: 0;
    z-index: 999;
  }
  .rightsidedata {
    margin-top: 10px !important;
  }
  
  .header {
    height: 70px !important; 
  }
  .logoclass {
    height: 70px !important;
  }
  
  .auth {
    height: 70px !important;
  }
  .logoutclass {
    height: 70px !important;
  }
  .buttonLogout {
    margin-top: 15px !important;
  }
  .rightsidedata {
    margin-top: 10px !important;
  }
  .adminsidebar {
    margin-top: 10px !important;
  
  }
  
  .Headers {
  margin-bottom: 15px !important;  
  height: 70px !important;
  }
  thead {
    height: 50px !important;
  } 
  .mainrow {
    margin-top: 10px !important;
  }
  
  .div_maintb td:nth-child(1) {
    height: 55px !important;
  }
  
  .Header {
    height: 65px !important;
  }
  
  .mainhomescreen {
    gap: 5px !important;
  }
  .edit .spanForm {
    left: 79% !important;
    margin-top: -60px !important;
  }
  .ButtonSection {
    margin-right: 0;
  }
  .loginheader > H2 {
    font-weight: 800 !important;
    font-size: 28px;
  }
  label.Multipleownerlabel {
    padding-left: 0;
    text-align: center;
    padding-top: 10px;
    font-size: 14px !important;
    padding-left: 20px;
  }
  .rightsidedata .Headers {
    padding-left: 15px !important;
  }
  button.filterbtn {
    height: 40px;
  }

  button.filterbtn {
    margin-top: -57px !important;
  }

  .searchDropdown {
    width: 175px !important;
    margin: 15px 7px !important;
  }

  .filtertextform {
    padding: 5px !important;
    margin: 0 10px;
  }

  .SubmitButton {
    width: 145px;
    height: 51px;
    background: linear-gradient(89.98deg, #19469d 23.67%, #00a3ff 97.94%);
    border-radius: 20px;
    color: white;
    border: none;
    font-size: 16px;
    margin-right: 15px !important;
  }

  .userfilter.userFilter .filtertextform > input {
    margin: 10px 12px !important;
    width: 165px !important;
    height: 42px;
  }

  .filtertextform > select {
    margin: 12px 12px !important;
    width: 180px !important;
  }

  .row.mainrow .react-date-picker__inputGroup {
    width: 370px !important;
  }

  .results {
    align-items: center;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
  }

  span.InputAddhorse5 input {
    font-size: 12px;
    width: 85px !important;
  }

  .userfilter {
    display: flex;
    align-items: center;
  }

  .css-b62m3t-container {
    width: 100% !important;
    font-size: 11px !important;
    line-height: 11px;
  }

  .myselectiondata.myselectiondataaddHorse2.editver span {
    flex: 0 0 19%;
    margin-right: 40px;
  }

  .div_maintb {
    height: 70vh !important;
  }

  .react-date-picker__button {
    margin-top: 5px;
    height: 0 !important;
  }

  .react-calendar {
    width: 100% !important;
    position: relative;
  }

  .myselectiondataaddHorse > span:nth-last-child(2) {
    border: unset !important;
    justify-content: flex-end;
    margin-right: 30px !important;
  }

  .myselecthorse.myresult .myselecthorsedata span {
    flex: 0 0 16% !important;
    margin: 0 !important;
  }

  .myselectdata.resultsdata span {
    flex: 0 0 16% !important;
    margin-right: 29px;
  }

  .resulttopheader .filtertextform > input {
    width: 285px !important;
    margin-right: 17px !important;
    margin: 10px 0px;
  }

  .resulttopheader .filtertextform {
    padding: 20px 0 !important;
    margin: 0 10px;
  }

  .resulttopheader button.filterbtn {
    margin-top: -85px !important;
  }

  .myselectdata.myaddmore span {
    flex: 0 0 12.2%;
    margin-right: 35px;
  }

  .myselectdata.myaddmore span:nth-child(12) {
    margin-left: 0px;
    margin-right: 15px !important;
  }

  label {
    font-size: 12px !important;
  }

  .react-date-picker__button {
    margin-top: 15px !important;
    height: 0 !important;
  }

  .div_maintb td:nth-child(1) {
    padding-left: 8px;
    padding-right: 100px;
  }

  .userfilter.userFilter .myuser.filtertextform > input {
    margin: 10px 12px !important;
    width: 140px !important;
    height: 42px;
  }
  .DashboardCard div {
    height: 200px;
    width: 30% !important;
  }
  .dashboardheader {
    border-radius: 0px 0px 0px 45px !important;
  }
}

@media only screen and (max-width: 1140px) {
  .DashboardCard div {
    width: 255px;
    height: 180px;
  }
  .deletedDropdown {
    top: 90px !important;
  }
  .searchDropdown {
    width: 155px !important;
    margin: 15px 5px !important;
  }

  .userfilter.userFilter .filtertextform > input {
    margin: 10px 12px !important;
    width: 135px !important;
    height: 41px;
  }

  .row.mainrow .react-date-picker__inputGroup {
    width: 310px !important;
  }

  button.filterbtn {
    height: 40px !important;
  }

  .ButtonSection div:first-child {
    padding-top: 5px;
    width: 55%;
  }

  .filtertextform > select {
    margin: 12px 12px !important;
    width: 135px !important;
  }

  .ButtonSection div:first-child svg.crossIcon {
    position: relative;
    left: 140px;
    top: -25px;
  }

  .react-date-picker__button {
    margin-top: 15px !important;
    height: 0 !important;
  }

  span.ViewCalulatedPrize1 {
    font-size: 14px;
  }

  .div_maintb td:nth-child(1) {
    padding-left: 8px;
    padding-right: 100px;
  }

  button.filterbtn {
    margin-top: -57px !important;
  }

  .userfilter.userFilter .myuser.filtertextform > input {
    margin: 10px 12px !important;
    width: 122px !important;
    height: 41px;
  }
  .loginheader {
    width: 55% !important;
  }
  .topheading {
    font-size: 20px;
    padding-left: 30px;
  }
  .DashboardCard {
    gap: 20px !important;
    margin: 1rem !important;
  }
  .fetchTrack {
    right: 10%;
}
}

@media only screen and (max-width: 1030px) {
  .DashboardCard div {
    width: 225px;
    height: 160px;
  }

  .DashboardCard div p {
    font-size: 14px;
  }

  button.filterbtn {
    margin-top: -57px !important;
  }

  .searchDropdown {
    width: 130px !important;
    margin: 15px 6px !important;
  }

  .rdrInputRange {
    align-items: center;
    padding: 5px 8px;
  }

  .react-date-picker__inputGroup {
    width: 260px !important;
  }

  .userfilter.userFilter .filtertextform > input {
    margin: 10px 6px !important;
    width: 125px !important;
    height: 41px;
  }

  .filtertextform > select {
    margin: 12px 6px !important;
    width: 125px !important;
  }

  .row.mainrow .react-date-picker__inputGroup {
    width: 260px !important;
  }

  .ButtonSection div:first-child {
    padding-top: 5px;
    width: 50%;
  }

  span.ViewCalulatedPrize1 {
    font-size: 13px;
    padding: 10px 13px;
  }

  .div_maintb td:nth-child(1) {
    padding-left: 8px;
    padding-right: 100px;
  }

  .userfilter.userFilter .myuser.filtertextform > input {
    margin: 10px 6px !important;
    width: 110px !important;
    height: 41px;
  }
}

@media only screen and (max-width: 900px) {
  p.formodal {
    display: none;
  }
}


svg.editcomp1 {
  cursor: not-allowed !important;
}