.div_maintb1 {
  width: calc(100vw - 100px);
  overflow: scroll;
}

.div_maintb1 table {
  border-spacing: 0;
  margin-top: 10px;
}

.div_maintb1 th {
  position: sticky;
  top: 0;

  color: #d1d1d1;

  min-width: 300px;
  padding: 6px;

  font-weight: normal;
}

.div_maintb1 td {
  padding: 6px;
  color: black;
}

.div_maintb1 th:nth-child(1),
.div_maintb1 td:nth-child(1) {
  position: sticky;
  left: 0;
  border-radius: 20px;
}

.div_maintb1 th:nth-child(1) {
  z-index: 300;
}

.myselecthorse {
  margin-top: 10px;
  height: 58px;
  background: linear-gradient(289deg, #19469D 23.67%, #00A3FF 97.94%);
  border-radius: 20px;
  width: 97%;
  margin-left: 18px;

}

.myselecthorsedata {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-right: 14rem; */
  /* padding-left: 1rem; */
  width: 100%;
}

.myselecthorsedata>span {
  color: #fff;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  padding: 23px;
}

.myselectdata {
  margin-top: 15px;
  width: 97%;
  /* height: 58px; */
  /*   left: 1414px;
  top: 248px; */
  border-radius: 20px;
}

.myselectiondata {
  display: flex;
  align-items: center;
  margin-left: 17px;

}

.myselectiondata>span {
  flex: 2 1;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  margin-top: 18px;
  margin-right: 1px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  height: 55px;
  width: 116px;
}

.myselectiondata>span:first-child {
  /* padding: 6px; */
  /* flex: 1 1; */
  text-align: center;
  /* min-width: 116px; */
  /* background: rgba(0, 0, 0, 0.5); */
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  border-radius: 20px;
  font-family: 'Inter';
  font-style: normal;
  /* font-weight: 700; */
  /* font-size: 21px; */
  line-height: 25px;
  /* color: #FFFFFF; */
}

.sbmtbtn>button {
  width: 186px;
  height: 51px;

  margin-top: 20px;
  top: 955px;
  background: linear-gradient(89.98deg, #19469D 23.67%, #00A3FF 97.94%);
  border-radius: 20px;
  border: none;
  float: right;
  color: white;
  margin-right: 25px;
}

.css-1s2u09g-control {
  outline: none !important;
}

.css-1s2u09g-control:focus {
  outline: none !important;
}

.myselectiondata>span>p {
  font-size: 18px;
  padding: 2px;
}

.col-sm {
  margin: 10px;
  border-radius: 10px;
}

.css-1s2u09g-control {
  border-radius: 30px !important;
  width: 95% !important;
  height: 60px;
  margin-top: 3px;
  overflow: hidden;
}

.css-1s2u09g-control:focus {
  border-radius: 30px !important;
  width: 105% !important;

}

.css-1okebmr-indicatorSeparator {
  margin-top: 0px !important;
}

/* .css-1s2u09g-control{
grid-area: none !important;  
} */

.starstyle {
  text-align: center;
  margin-top: 14px;
  display: flex;
  justify-content: space-evenly;
  font-size: 18px;
  color: #8f8d8d;
}

.col-sm {
  position: relative;
  font-family: sans-serif;
  font-size: 14px;
  width: max-content;
}

.placeholder {
  position: absolute;
  right: 4px;
  top: 2px;
  pointer-events: none;
  opacity: .5;
}

.input:placeholder-shown+.placeholder {
  /* if real placeholder is shown - hide fake placeholder */
  opacity: 0;
}

.importcsv {
  margin: 10px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}

.UploadCSV1 {
  float: right;
}

.flexes>span:first-child {
  flex: 1;
}

.flexes>span:nth-child(2) {
  flex: 15;
}

.myselectiondata.aaaaa {
  /* background: aliceblue !important; */
  margin-left: 14px !important;
  width: 102% !important;
  /* margin-top: 1px; */
}

button.savebtnhorse {
  border: none;
  height: 50px;
  width: 95px;
  min-width: 70px;
  border-radius: 18px;
  margin-top: 15px;
  background: linear-gradient(89.98deg, #19469D 23.67%, #00A3FF 97.94%);
  color: #fff;
}

button.removebtnhorse {
  border: none;
  height: 50px;
  width: 95px;
  border-radius: 18px;
  margin-top: 15px;
  background: #8f8d8d;

  min-width: 68px;
  color: #fff;
}

input.Remarksverdict {
  margin: 8px;
  height: 40px;
  width: 93%;
  border: none;
}



.myselecthorsedata>span:nth-child(5) {
  margin-left: 52px;
}

.myselectiondataaddHorse>span {
  flex: 2 1;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  margin-top: 18px;
  margin-right: 1px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  height: 55px;
  width: 116px;

}



.myselectiondataaddHorse>span input {
  border: none;
  margin: 15px;
  width: 65px;
}

.myselectiondataaddHorse {
  display: flex;

  margin-left: 17px;
}

.myselectiondataaddHorse3 {
  width: 110%;
}

.verifybtn {
  border: 1px solid #9c9999;
  width: 140px;
  text-align: center;
  border-radius: 20px;
  margin-top: 5px !important;
  color: #9c9999;
  cursor: pointer;
  margin: auto;
  margin-right: 15px;
}

span.morePopup {
  margin-left: 166px;
}

.spanForm1 {

  margin-top: -67px !important;

  margin-right: -49px !important;
}

form .addmore1:nth-child(1) {
  margin-right: 1px;
  margin-top: 23px;
  font-size: 19px;
  padding: 0px 10px !important;
}

.pointsSelectHeader {
  display: flex;
  background: linear-gradient(89.98deg, #19469D 23.67%, #00A3FF 97.94%);
  height: 60px;
  text-align: center;
  align-items: center;
  color: white;
  justify-content: space-around;
  border-radius: 60px;
}

.pointsForm {
  display: flex;
  margin-top: 40px;
}

.pointsForm>input {
  width: 20%;
  margin: 0px 6px;
  border: 1px solid rgb(161, 158, 158) !important;
  height: 65px;
}

.pointsForm>button {

  margin-left: 135px;
}

.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 86%;
  height: 100vh;
  color: white;
  animation: colorSlide 15s cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
}

.text-center {
  text-align: center;
}

.flex-container>h1,
h3 {
  margin: 10px;
  cursor: default;
}

.fade-in {
  animation: fadeIn 2s ease infinite;
}


.flex-container>h1 {
  font-size: 8em;
  transition: font-size 200ms ease-in-out;
  border-bottom: 1px dashed white;
}

span#digit1 {
  animation-delay: 200ms;
}

span#digit2 {
  animation-delay: 300ms;
}

span#digit3 {
  animation-delay: 400ms;
}

.flex-container>button {
  border: 1px solid white;
  background: transparent;
  outline: none;
  padding: 10px 20px;
  font-size: 1.1rem;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  transition: background-color 200ms ease-in;
  margin: 20px 0;


}


@keyframes colorSlide {
  0% {
    color: #698fff;
  }

  25% {
    color: rgb(30, 78, 222);
  }

  50% {
    color: rgb(30, 14, 136);
  }

  75% {
    color: rgb(4, 4, 126);
  }

  100% {
    color: #011143;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.statusBtn {
  padding: 6px 18px;
  text-align: center;
  border-radius: 10px;
  font-size: 13px;
  color: #FFFFFF;
  border: none;
  background: #19469D !important;

}

nav .drop-down {
  list-style: none;
  overflow: hidden;
  /* When ul height is reduced, ensure overflowing li are not shown */
  height: 172px;
  /* 172px = (38 (li) + 5 (li border)) * 4 (number of li) */
  background-color: #34495e;
  font-family: Arial;
  width: 200px;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  -webkit-transition: height 0.3s ease;
  transition: height 0.3s ease;
}

nav .drop-down.closed {
  /*  When toggled via jQuery this class will reduce the height of the ul which inconjuction
      with overflow: hidden set on the ul will hide all list items apart from the first */
  /* current li height 38px + 5px border */
  height: 43px;
}

nav .drop-down li {
  border-bottom: 5px solid #2c3e50;
}

nav .drop-down li a {
  display: block;
  color: #ecf0f1;
  text-decoration: none;
  padding: 10px;
  /* Larger touch target area */
}

nav .drop-down li:first-child a:after {
  content: "\25BC";
  float: right;
  margin-left: -30px;
  /* Excessive -margin to bring link text back to center */
  margin-right: 5px;
}

.compBtn {
  background-color: black;
  color: #ffff;

}

.dueBtn {
  background-color: #FF9900;
  color: #ffff;

}

.liveBtn {
  background-color: #5EC30F;
  color: #ffff;



}

.horsedataassociation {
  display: flex;
  justify-content: space-between;
  width: 80%;
  font-size: 15px;
  padding: 3px;
  margin: 8px -35px;
}

div#selecthorseass {
  background: #19469D;
  border: 1px solid #000;
  padding: 3px 6px !important;
  margin: 20px -30px;
  border-radius: 0px;
}

button.replacebtn {
  border: none;
  background: #19469D;
  color: #fff;
  padding: 6px 50px;
  margin: 0px 85px;
}

.afterreplacediv {
  font-size: 16px;
}

.afterreplacediv p {
  margin: 5px -25px !important;
  text-decoration: underline !important;
}
