form {
  max-height: 100vh;


}

span.CodeMsg {
  font-size: 13px;
  color: green;
  position: absolute;
  top: 45px;
}

.Headers {
  font-weight: 700;
  font-size: 15.5949px;
  overflow: hidden;
  align-items: center;
  width: 99.5% !important;
  border-radius: 0px 20px 0px 20px !important;
  height: 80px;
  margin-left: 3px !important;
  background-color: #E8E8E8;

  padding-left: 38px !important;
  padding-top: 24px;
  margin-left: 20px !important;
  margin-top: -30px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 15.5949px;
  line-height: 19px;
  padding-top: 29px;
  margin-bottom: 30px;
  color: #000000;



}

.form input {

  margin-top: -4px;
  width: 97%;
  height: 50px;
  border: none;
  border-radius: 20px;
  border: none !important;
  padding-left: 20px;

  font-weight: 300;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;

}

.form>input::placeholder {

  font-weight: 600;
}

.form input:focus {

  outline: none;

}

.ButtonSection {

  display: flex;
  justify-content: space-between;
  margin-right: 27px;
  margin-top: 4rem;

}



.SubmitButton {
  width: 186px;
  height: 51px;
  background: linear-gradient(89.98deg, #19469D 23.67%, #00A3FF 97.94%);
  border-radius: 20px;
  color: white;
  border: none;
  font-size: 16px;
}



.RaceButtonDiv {

  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
  margin-top: 5rem;

}

.updateButton {
  /* margin-top: 1.5rem; */
  width: 186px;
  height: 51px;
  background: linear-gradient(89.98deg, #19469D 23.67%, #00A3FF 97.94%);
  border-radius: 20px;
  color: white;
  border: none;
  margin-right: 2.8rem;
  padding: 10px;
}


.AddAnother {


  background: rgba(202, 202, 202, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  width: 250px;
  height: 58px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 25px;

  color: #8C8C8C;
  margin-top: 20px;

  margin-left: 17px;
}

.AddAnother1 {


  background: rgba(202, 202, 202, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  width: 100px;
  height: 58px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 25px;

  color: #8C8C8C;
  margin-top: 20px;

  margin-left: 17px;
}

.AddAnother>svg {


  color: #8C8C8C;
  font-weight: 900;
  margin-left: 15px;
}

.selectdir {
  direction: rtl;
  margin-left: 48px;
  width: 100%;
}





input::file-selector-button {

  color: white;
  background: linear-gradient(89.98deg, #19469D 23.67%, #00A3FF 97.94%);
  ;
  border-radius: 20px;

  border: none;
  width: 186px;
  height: 51px;
  padding: 10px 20px;
  cursor: pointer;
}

.mainrow {
  margin-top: 15px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  height: 62px;
  border-radius: 20px;
  width: 97%;
  margin: 0px auto !important;
  margin-top: 25px !important;
}


.css-1s2u09g-control:focus {
  outline: none !important;
  border: none !important;



}

.css-13cymwt-control:focus {
  outline: none !important;
  border: none !important;



}

.css-t3ipsp-control:focus {
  outline: none !important;
  border: none !important;



}

.css-t3ipsp-control:active {
  outline: none !important;
  border: none !important;



}

.css-b62m3t-container:focus {
  outline: none !important;
  border: none !important;
  outline: none !important;


}

.spanForm {
  display: flex;
  justify-content: flex-end;
  font-size: 2rem;
  margin-top: -52px;
  font-weight: 100;
  color: lightgray;
}


.css-1s2u09g-control:active {
  border: none !important;
  outline: none !important;
}

.css-1s2u09g-control::before {
  border: none !important;
  outline: none !important;
}

.css-1s2u09g-control:focus {
  border: none !important;
  outline: none !important;
}

.singleButtonSection {

  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
  margin-top: 5rem;
  margin-left: 30px;
}

.css-1pahdxg-control {


  border: transparent !important;
  box-shadow: none !important;
}

.css-1s2u09g-control {

  border: transparent !important;
  box-shadow: none !important;

}

.css-1s2u09g-control {
  border-radius: 30px !important;

  height: 37px;
  border: 1px solid #fff !important;
}

.css-b62m3t-container {
  position: relative;
  box-sizing: border-box;

  padding: 0px 10px !important;
}



.css-1okebmr-indicatorSeparator {
  display: none;
}

.css-b62m3t-container {
  position: relative;
  box-sizing: border-box;
  width: 95%;
  max-height: 50px;
  margin-top: -10px;
  height: 50px;
  border: none !important;
  border-radius: 20px;
  padding-bottom: -10px;
  padding-left: 20px;
  padding-top: 7px;
  font-weight: 300;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
}

.css-1s2u09g-control {

  height: 11px !important;
}

.css-14el2xx-placeholder {
  color: hsl(0, 0%, 50%);
  grid-area: 1/1/2/3;
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
  padding-right: 2px !important;
}

.css-tj5bde-Svg {
  display: inline-block;
  fill: currentColor;
  line-height: 1;
  stroke: currentColor;
  stroke-width: 0;
  margin-left: -54px;
}

.form-switch {
  padding: -0.5em;
  border: none;
  margin: 0px 0px 0p;
  font-size: 25px;
  /* margin-left: 60px; */

  margin-top: 7px;
}

.EmailForget {
  width: 100% !important;
}

.EmailForgetBtn {
  margin: 25px;
}

.ForgetPassword p {
  text-align: center;
  padding: 15px;
  color: #ef3f3f;
  font-weight: 500;
  cursor: pointer;
}

.css-6j8wv5-Input {
  margin: -3px;
  padding-bottom: 2px;
  padding-top: 2px;
  visibility: visible;
  color: hsl(0, 0%, 20%);
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: inline-grid;
  grid-area: 1/1/2/3;
  grid-template-columns: 0 min-content;
  box-sizing: border-box;
}

.css-t3ipsp-control {
  border: none;

}

.css-14el2xx-placeholder {
  color: hsl(0, 0%, 50%);
  grid-area: 1/1/2/3;
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

span.react-date-picker__inputGroup__divider {
  display: none !important;
}

.react-date-picker__wrapper {
  display: flex;
  flex-grow: 2;
  width: 100% !important;
  flex-shrink: 0;
  border: none !important;
}

.react-date-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
  width: 687px;
}

.react-datetime-picker.react-datetime-picker--closed.react-datetime-picker--enabled {
  width: 100%;
}

.react-datetime-picker.react-datetime-picker--open.react-datetime-picker--enabled {
  width: 100%;
}

.react-datetime-picker__wrapper {
  border: none;
}

.react-datetime-picker__inputGroup__input {
  min-width: 2.54em;
}

.react-datetime-picker__inputGroup__divider {
  display: none;
}

.react-datetime-picker__wrapper {
  border: thin solid #fff !important;
}

.react-date-picker__calendar .react-calendar {
  border-width: 3px !important;

  font-size: 12px;
  border-color: #19469D;
}

.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
  font-size: 12px;
  box-sizing: border-box;
}

/* .col-sm:after {
  content: " *";
  font-size: 31px;
  color: rgb(214, 78, 78);
  display: flex;
  align-items: center;
  margin: -36px 0px 0px 1px;
} */
.addmore {
  position: relative;
  background: transparent;
  border: none;
  color: lightgray;
  margin-right: 7px;
  margin-top: 10px;
  cursor: pointer;
  padding: 0px 0px 0px 6px !important;
}

.displaynew {
  display: none !important;
}

.textverdict {
  border: none;
}

.textverdict:focus-visible {
  border: none !important;
}

.verdictname {
  max-width: 300px !important;
}

.mb-3.form-floating {
  margin-top: -2px;
  width: 88%;
  height: 32px;
  border: none;
  border-radius: 20px;
  border: none !important;
  padding-left: -1px;
  font-weight: 300;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
}

.mb-3.form-floating:active {
  border: none;

}

.form-floating>.form-control,
.form-floating>.form-control-plaintext,
.form-floating>.form-select {
  height: calc(2.5rem + 2px);
  line-height: 2.25;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25);
}

.form-floating>.form-control-plaintext:focus,
.form-floating>.form-control-plaintext:not(:placeholder-shown),
.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
  font-size: 14px;
}





.form-floating>label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 0.8rem 0.25rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  color: #8a9097 !important;
}

.floatingInputAr>label {
  right: -31px;
  left: initial;

}

button.Approvedbtn.resultbtn {
  color: #000;
  margin-left: -30px;

}

input#floatingInput {
  margin-right: -28px;
}

.css-1rhbuit-multiValue {
  background-color: hsl(0, 0%, 90%);
  border-radius: 2px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 2px;
  /* min-width: 0; */
  /* width: auto; */
  width: fit-content !important;
  box-sizing: border-box;
  max-width: 83px !important;
}

.css-12jo7m5 {
  border-radius: 2px;
  color: hsl(0, 0%, 20%);
  font-size: 85%;
  overflow: hidden;
  padding: 3px;
  padding-left: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  margin-left: 15px !important;
}

.css-tj5bde-Svg {
  display: inline-block;
  fill: currentColor;
  line-height: 1;
  stroke: currentColor;
  stroke-width: 0;
  margin-left: 15px !important;

}

.css-1rhbuit-multiValue {
  background-color: hsl(0, 0%, 90%);
  border-radius: 2px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 2px;
  width: fit-content !important;
  box-sizing: border-box;
}

.css-g1d714-ValueContainer div {
  width: 140px !important;
  margin-right: 10px;
}

.css-1rhbuit-multiValue {
  background-color: hsl(0, 0%, 90%);
  border-radius: 2px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 2px;
  min-width: 100px;
  justify-content: space-between;
  box-sizing: border-box;
  max-width: 150px !important;
  display: flex !important;
}

.css-1rhbuit-multiValue svg {
  position: relative;
  left: 20px;
}

table.Prizeclass {
  width: 100%;
  border-spacing: 0;
}

.Prizeclassthead {
  text-align: center;
  font-size: 16px;
  color: white;
}

.Prizeclasstbody {
  text-align: center;
  font-size: 15px;

}

.Prizeclasstbody>tr {
  border-width: 0;
  padding-top: 10px
}

.react-datetime-picker__inputGroup__input:invalid {
  background: transparent !important;
}

.react-date-picker__inputGroup__input:invalid {
  background: transparent !important;
}

.spanForm {
  display: flex;
  font-size: 2rem;
  margin-top: -53px;
  font-weight: 100;
  color: lightgray;
  justify-content: flex-end;
  margin-left: 134px !important;
  margin-right: -30px;
}

.floatingInputAr>label {
  /* right: -31px; */
  left: initial;
  /* padding-right: 44px; */
}

.Lottie {
  display: flex;
  margin-left: 30%;
  top: -10 !important;
}

label.Multiplecaplabel {
  padding: 10px 0px 10px 35px;
  font-weight: 600;
}

.Multipleownerlabel {
  padding: 0px 0px 10px 35px;
  font-weight: 600;
}

.starcss {
  margin-top: -22px;
}

.css-1gtu0rj-indicatorContainer {
  padding: 25px !important;
}

.multidropdown.css-b62m3t-container {
  margin-top: 5px !important;
  padding: 0px 15px !IMPORTANT;
}

.SelectOwnerimage {
  margin-top: 25px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: transparent;

  opacity: 1;
}

td.table_delete_btn1 {
  padding: 10px 30px !important;
}

.fileinputdata {
  width: 200px;
}

.topheading {
  padding: 20px;
  font-weight: 500;
}

.form input#floatingInput:required {
  border-color: #800000 !important;
  border-width: 3px !important;

}

.form :required:invalid {
  border-color: #c00000 !important;
}

.has-success .input#floatingInput {
  border-bottom: 2px solid #168b3f !important;
}

.has-danger {
  border-bottom: 2px solid #dc1d34 !important;
}

.email-field {
  outline: 0;
}

.input#floatingInput:valid {
  border: 1px solid green;
}

.input#floatingInput:invalid {
  border: 1px solid red;
}

/* .cell {
  max-width: 80px; 
  white-space : nowrap;
  overflow : hidden;
} */
/* .cell::-webkit-scrollbar {
  width: 0.4em;
} */

.page-link:active {
  background-color: #00A3FF;
}

.addresultform {
  border: none !important;
}

.quill {
  height: 170px;
  margin: 0 19px;
  border-radius: 13px !important;
  width: 95%;
}

.QuillAr {
  text-align: right;
  direction: rtl;
}



.pickCountstyle {
  margin: 34px;
  font-size: 15.59px;
}

.error {
  font-size: 11px;
  position: absolute;
  color: red;
  top: 45px;
}

.errorAr {
  font-size: 11px;
  direction: rtl;
  display: flex;
  color: red;
  position: absolute;
  top: 45px;
  right: 0;
}

.successAr {
  font-size: 11px;
  direction: rtl;
  display: flex;
  color: #228B22;
  position: absolute;
  top: 45px;
  right: 0;
}


.addbtn.competitionaddbtn {
  margin-top: 35px;
}

.newsError {
  color: red;
  font-size: 9px;
  position: relative;
  top: 36px;
  left: 18px;
}

.newsSuccess {
  color: #228B22;
  font-size: 11px;
  position: relative;
  top: 36px;
  left: 18px;
}

.newsErrorAr {
  color: red;
  font-size: 11px;
  float: right;
  margin-top: 47px;
  margin-right: 18px;
}

.newsSuccessAr {
  color: #228B22;
  font-size: 11px;
  float: right;
  margin-top: 47px;
  margin-right: 18px;
}

.success {

  color: #228B22;
  font-size: 11px;
  position: absolute;
  top: 45px;
}

.userfilter {
  display: flex;
  BACKGROUND-COLOR: white;
}

.calenderuser {
  margin-left: 15px;
}

.calendericon {
  margin: 0px 20px 0 25px;
  font-size: 36px;
  color: #000;
  cursor: pointer;
}

.filtertextform {
  padding: 20px;
  margin: 0 10px;
}

.filtertextform>input {
  margin: 24px;
  width: 295px;
  height: 45px;
}

.filtertextform>select {
  margin: 24px;
  width: 289px;
}

button.filterbtn {
  float: right;
  margin: -86px 54px 0 0;
  background: linear-gradient(89.98deg, #19469D 23.67%, #00A3FF 97.94%);
  border-radius: 40px;
  width: 186px;
  height: 50px;
  color: white;
  border: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15.5949px;
  line-height: 19px;
}

a.csvclass {
  text-decoration: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 15.5949px;
  line-height: 19px;
  padding-top: 7px;
  color: #000000;
  padding-left: 6px;
}

.myselectioncompetition {

  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.myselectioncompetition>span {
  color: #fff;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  padding: 18px;
}

.myselectioncompetition>span:nth-child(2) {
  color: #fff;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  margin-left: 30px;
}

p.competitionrace1 {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.5);
  margin: 17px;
}

input[type='checkbox'] {
  width: 20px;
  height: 100%;
}

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  height: 27px;
}

.quillRow {
  margin-top: 22px;
}

.newsError {
  color: red;
  font-size: 11px !important;
  position: relative;
}

.newsErrorAr {
  color: red;
  font-size: 11px !important;
  position: relative;
}

/* .crossIcon {
  display: block;
  margin-left: 157px;
  margin-bottom: -18px;
  margin-top: 10px;
  height: 14px;
} */
.react-time-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: none !important;
}

.react-time-picker {
  display: inline-flex;
  position: relative;
  width: 48%;
}


.editDate::placeholder {
  width: 656579px !important;
}





p.selectLabel {
  position: relative;
  top: 11px;
  left: 29px;
  font-size: 11.5px;
  z-index: 1;
  line-height: 0px;
  margin: 2px;
  color: #8d8d8a;
}

p.dateLabel {
  position: relative;
  top: 11px;
  left: 30px;
  font-size: 11px;

  line-height: 0px;
  margin: 2px;
  color: #8d8d8a;
}

.sponsorPlaceholder img {
  width: 40px;
  height: 40px;

}

.parentTemplate {
  width: 86%;
  margin: 40px 9px;
}

.templateHeader {

  margin-left: 29px;
  height: 60px;
  background-color: #e9e2e2;
  align-items: center;
  display: flex;
  width: 93.5%;
}

.templateInputs {

  width: 100%;
  margin: 10px 25px;

}

.templateInputs input {
  width: 95.3%;
  margin-left: 8px !important;
  height: 38px;
  border: 1px solid rgb(204, 202, 202);
  font-size: 18px;
}

.quill {
  height: fit-content;
  margin: 0 14px;
  border-radius: 13px !important;
  width: 97.5%;
}

.ql-container.ql-snow {
  min-height: 260px;
}

.accordion {
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

span.spanfordropdown {
  min-width: 283px;
}

.searchDropdown {
  width: 290px;
  margin: 24px 18px;
  margin-top: 26px;
  border: 1px solid lightgrey;
  height: 40px;
  padding: 10px;
}


/* .abc .css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}
.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
} */

.react-date-picker__inputGroup {
  padding: 0px 2px !important;
}

input#custom-switch {
  height: 44px;
  width: 90px;
  margin-top: 14px;
}

.inputAddhorse {
  width: 100px;
  min-width: 100px;
}

/* .InputAddhorse2 {
  min-width: 200px;
} */

.InputAddhorse3 {
  min-width: 145px;
}


.inputAddhorse1 {
  min-width: 60px;
}

.react-date-picker__calendar {
  width: 350px;
  max-width: 100vw;
  z-index: 2;
}

.css-2613qy-menu {
  background-color: white !important;
  z-index: 2 !important;
}

.calcualtedPrizeTable>tr {
  border: none
}

.calcualtedPrizeTable>tr>th {

  padding-top: 10px;
  padding-bottom: 15px;
  text-align: left;
  background: linear-gradient(180deg, #078BE6 0%, #19469D 100%), #19469D;
  color: white;
  border-radius: 10px;
}

.calcualtedPrizeTable>tr>td {

  border: 1px solid #ddd;
  padding: 8px;
}

.calcualtedPrizeTable>tr>th {

  border: 1px solid #ddd;
  padding: 8px;
}

.calcualtedPrizeTable>tr:hover {
  background-color: #ddd;
}

.css-13cymwt-control {
  background-color: rgb(255, 255, 255) !important;
  border-color: none !important;
  border-radius: 0px !important;
  border-style: none !important;
  border-width: 0px !important;
  box-sizing: border-box;
  outline: 0px !important;
  max-height: 50px;
  margin-top: 3px;
}

.css-t3ipsp-control {
  border: none !important;
  border: transparent !important;
  outline: none !important;
  border-width: 0px !important;
  box-shadow: none !important;
}



input.verdictdataclass {
  width: 120px !important;
}

.css-1u9des2-indicatorSeparator {
  width: 0px !important;

}

input.dateforrace {
  margin-top: 1px;
  width: 197px;
  align-items: center;
  margin: auto;
}

input[type="date"]::before {
  content: attr(data-placeholder);
  width: 100%;
}

/* hide our custom/fake placeholder text when in focus to show the default
 * 'mm/dd/yyyy' value and when valid to show the users' date of birth value.
 */
input[type="date"]:focus::before,
input[type="date"]:valid::before {
  display: none
}

.css-1nmdiq5-menu {
  background-color: white !important;
  z-index: 999 !important;
}

.css-t3ipsp-control {
  height: 10px !important;
  margin-top: 3px !important;

}

.css-tlfecz-indicatorContainer {
  padding: 5px 25px !important;
}

.setHeight {
  min-height: 60vh !important;
}

.fetchNewVerdict {
  position: relative;
  background: transparent;
  border: none;
  /* color: lightgray; */
  margin-right: 7px;
  font-size: 39px;
  color: w;
  margin-top: -8px;
  cursor: pointer;
  /* background: #3333c5; */
  height: 63px;
  padding: 0px 0px 0px 6px !important;

}

.fetchNewVerdict:hover {


  color: #2d56a8;


}

.inputAddhorse3>input {
  width: 80% !important;
}

span.inputAddhorse4>img {
  margin: 14px;
}

.editsilkcolorapi {
  display: flex;
  justify-content: center;
  align-items: center;
}

.editsilkcolorapi img {
  height: 50px;
  width: 50px;
}

.editsilkcolorimg {
  display: grid;
  gap: 15px;
  margin: 10px;
}

.editsilkcolorimg button {
  border: none;
  background: #f65252;
  border-radius: 10px;
  color: #fff;
}

.crossIcon1 {
  position: relative;
  left: -76%;
  top: 35px;
}

.results {
  display: flex;
  background-color: white;
  align-items: center;
}

.resultDropdown {
  width: 214px;
  margin: 24px 18px;
  margin-top: 26px;
  border: 2px solid lightgrey;
  height: 55px;
  padding: 10px;
  border-radius: 10px;

}

.react-date-picker__calendar {
  width: 350px;
  max-width: 100vw;
  z-index: 2 !important;
}

.pointsAdd {

  display: flex;
  background-color: white;
  align-items: center;
  justify-content: space-around;

}

.pointsDropdown {

  width: 20%;
  margin: 24px 18px;
  margin-top: 26px;
  border: 1px solid lightgrey;
  height: 50px;
  padding: 6px 10px;
  border-radius: 10px;

}.css-b62m3t-container {
  padding: 0 !IMPORTANT;
}

.pointsAdd>input {

  width: 20%;
  margin: 24px 18px;
  margin-top: 26px;
  border: 1px solid lightgrey;
  height: 42px;
  padding: 10px;
  border-radius: 10px;


}

.groupnametop {
  width: 20%;
}

.casttablerank {
  color: #000;
}

td.casttablerank {
  color: black !important;
}

tbody.casttablebody td {
  width: 100px;
  height: 50px;

}

tbody.casttablebody td>input {
  height: 30px;
}

.saveinputpoint {
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.casttable {
  text-align: center;
}

.racesinCompetition {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 20px;
}

.inputAddraces {
  width: 33%;
}

.inputAddraces {
  width: 33%;
  border: 1px solid rgb(168, 168, 168);
  padding: 1px 20px;
  border-radius: 30px;
  height: 50px;

}

input.racecompmappimg {
  border: none;
  padding: 10px;
}



.addmorecomp {
  position: relative;
  cursor: pointer;
  margin-right: 5px;
  margin-top: 7px;
  font-size: 19px;
  padding: 2px 9px !important;
  border: none;
  color: lightgray;
  background: #19469d;
  border-radius: 10px;
  text-align: center;
  align-items: center;
  width: 40px;

}

.spanFormcomp {
  display: flex;
  font-size: 2rem;
  margin-top: -52px;
  font-weight: 100;
  color: lightgray;
  justify-content: flex-end;
  margin-left: 134px !important;
  margin-right: -17px;

}

.pointsDropdowns {
  width: 17%;
  border: 1px solid #a99f9f;
  padding: 11px 8px 18px 0px;
  border-radius: 18px;
  text-align: left;
}

.addmorecomp1 {
  margin-top: 16px !important;
}

.pointsForm>button {
  margin: 22px 108px;
}

.SubmitButton:disabled,
.SubmitButton[disabled] {
  border: 1px solid #999999;
  color: #666666;
  background: linear-gradient(186.98deg, #010306 23.67%, #4d5a62 97.94%);
}

.defaultLoader {
  width: 400px;
}

.loaderDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;




}



.swatch {
  background: #fff;
  box-shadow: 1em 1em 1em rgba(0, 0, 0, .1);
  display: flex;
  flex-direction: column;
  width: 15.1em;
  margin: 35px;
  height: 10em;
  border-radius: 10px !important;
}

.swatch input[type="color"] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  height: 10em;
  padding: 0;
  width: 15em;
  border-radius: 10px;
}



::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-color-swatch {
  border: 0;
  border-radius: 10px;
}

::-moz-color-swatch,
::-moz-focus-inner {
  border: 0;
}

::-moz-focus-inner {
  padding: 0;
}

.fetchTrack {
  position: absolute !important;
  right: 6%;
  top: 0%;


  cursor: pointer;
}

button.Approvedbtn.editraceimage {
  padding: 12px;
  margin: 5px;
  color: #fff;
  height: min-content;
  font-weight: 600;
  width: 150px;
  background: linear-gradient(89.98deg, #19469D 23.67%, #00A3FF 97.94%);
}
 input:focus {
  border: 1px solid lightgray;
}
.purple {
  background-color: #4547EE;
}

.red {
  background-color: #DE3E28;
}

.competitionPopup {

  min-height: 200px;
}

.compSubmit button {
  background: linear-gradient(89.98deg, #19469D 23.67%, #00A3FF 97.94%);
  padding: 10px 40px;
  color: #fff;
  border: none;
  border-radius: 20px;
  font-size: 18px;
  font-family: inherit;
  letter-spacing: 1px;
}

.compSubmit {
  align-items: end;
  display: flex;
  justify-content: flex-end;
}

.compSubmit button {
  background: linear-gradient(89.98deg, #00A3FF 23.67%, #19469D 97.94%);
  transition: all 200ms ease-in-out !important;

}

span.videosuccess {

  color: #fff;
  font-size: 11px;
  position: absolute;
  top: 271px;
  background: #228B22;
  padding: 0 5px;
  border-radius: 5px;
  font-size: 10px !important;
  left: 32%;
  width: fit-content;
}

span.videoerror {

  color: #fff;
  font-size: 11px;
  position: absolute;
  top: 240px;
  background: rgb(255, 0, 0);
  padding: 0 5px;
  border-radius: 5px;
  font-size: 10px !important;
  left: 32%;
  width: fit-content;
}

.inputFile input[type='file'] {
  font-size: 0 !important;
}

.inputFile ::file-selector-button {
  font-size: initial !important;
}

.ButtonSection svg {
  vertical-align: middle;
  position: relative;
  right: 66%;
  margin-top: 106px;
  z-index: 9999;
}

/* .dateInput input::placeholder {

  width: 82px !important;
} */

.react-date-picker.react-date-picker--closed.react-date-picker--enabled {
  margin-top: -5px !important;
}

.editDate input.react-date-picker__inputGroup__input.react-date-picker__inputGroup__month {
  width: 82px !important;
}

.casttable {
  text-align: center;
  margin-top: 30px;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  border-width: 0px !important;
}

.ant-input:focus,
.ant-input-focused {
  border-color: transparent !important;
  box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
  border-inline-end-width: 1px;
  outline: 0;
}

.spacer {
  margin-top: -40px !important;
  position: absolute;
  right: 0;
}

.ant-select.ant-select-in-form-item {
  width: 93%;
  height: 50px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
}

.selectSpacer {
  margin-top: -90px;
  position: absolute;
  right: 0;
}

.ant-form-item-control-input-content {
  flex: auto;
  max-width: 90%;
}

.ant-picker {
  width: 100%;
  height: 50px !important;
}

.spacer1 {
  margin-top: -56px !important;
  position: absolute;
  left: 86% !important;
}